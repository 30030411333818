import React, { useState, useEffect, Component } from 'react';
import {Link} from "react-router-dom";
import AOS from 'aos'
import 'aos/dist/aos.css'
import $ from "jquery";
import logo from "../images/geely.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftRotate, faChevronRight, faCopyright} from '@fortawesome/free-solid-svg-icons'
import { faSquareFacebook, faSquareInstagram, faSquareTwitter } from "@fortawesome/free-brands-svg-icons";
import map from "../images/map.png";
import call from "../images/call.png";
import cmlogo from "../images/cm.png";


import {
  InMemoryCache,
  ApolloClient,
  gql,
} from '@apollo/client';

const client = new ApolloClient({
  cache:  new InMemoryCache(),
  uri: "https://geely-cms.cs-labs.online/graphql"
});




function Footer(props) {


const [vehicleList2, setVehicleList2 ] = useState([])

const [vehicleList, setVehicleList ] = useState([])


let vehicleArray = []

useEffect( () => {
  client
.query({
  query: gql`
   query{
    vehicles {
      edges {
        node {
          id
          geely {
            gelly{
              mediaItemUrl
            }
            carBrochure
            brandName
            carDetails1
            carDetails2
            carDetails3
        
            carFeatures {
              carFeature
              carFeatureImgage {
                mediaItemUrl
              }
            }
            carImageLeft {
              mediaItemUrl
            }
            nameplateCaption
            nameplate {
              mediaItemUrl
            }
            carImageRight {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
  `,
})
.then(async result => {
  console.log(result)
  let automotiveDetailPack 
  

  let x
 
  //alert(result.data.vehicles.edges.length)

  if (vehicleArray.length != result.data.vehicles.edges.length){
  for(x=0; x < result.data.vehicles.edges.length; x++){ 
       
       automotiveDetailPack = [{carId:result.data.vehicles.edges[x].node.id, gelly:result.data.vehicles.edges[x].node.geely.gelly.mediaItemUrl, brand:result.data.vehicles.edges[x].node.geely.brandName, carBrochure:result.data.vehicles.edges[x].node.geely.carBrochure, nameplate:result.data.vehicles.edges[x].node.geely.nameplate.mediaItemUrl, namePlateCaption:result.data.vehicles.edges[x].node.geely.nameplateCaption, marketingDetail1:result.data.vehicles.edges[x].node.geely.carDetails1, marketingDetail2:result.data.vehicles.edges[x].node.geely.carDetails2, marketingDetail3:result.data.vehicles.edges[x].node.geely.carDetails3, carFeature1:result.data.vehicles.edges[x].node.geely.carFeatures[0].carFeature,carFeatureImage1:result.data.vehicles.edges[x].node.geely.carFeatures[0].carFeatureImgage.mediaItemUrl, carFeature2:result.data.vehicles.edges[x].node.geely.carFeatures[1].carFeature,carFeatureImage2:result.data.vehicles.edges[x].node.geely.carFeatures[1].carFeatureImgage.mediaItemUrl,carFeature3:result.data.vehicles.edges[x].node.geely.carFeatures[2].carFeature,carFeatureImage3:result.data.vehicles.edges[x].node.geely.carFeatures[2].carFeatureImgage.mediaItemUrl, imageRight:result.data.vehicles.edges[x].node.geely.carImageRight.mediaItemUrl, imageLeft:result.data.vehicles.edges[x].node.geely.carImageLeft.mediaItemUrl  }]
 
       vehicleArray.push(automotiveDetailPack)
   
        
  }
  console.log(vehicleArray)
  
  setVehicleList(vehicleArray)
  }


});

}, [])   




  let vehicleGelly2
  if(vehicleList.length > 0 ){
  vehicleGelly2 = vehicleList.map((vehicle, i) => 
   <Link to={`/${vehicle[0].carId}/explore`} className="link">
            <li className="newsDescriptionTex clearSpaceBottomFooter">     {vehicle[0].brand} </li> 
   
  


   </Link>
     
  )}
  
  return (
  <div> 

   <footer> 
    <div className="footerSection"> 

    <div className="row mx-auto"> 
       <div className="col-12 col-md-6  d-none d-sm-block"> 
        <span className="car-spec-img"> <img src={map} /> </span>
        <span> Km 32 Lekki Epe Expressway, Awoyaya </span>

       </div>
       <div className="col-12 col-md-6 text-end  d-none d-sm-block">
          <span className="car-spec-img"> <img src={call}  /> </span>
          <span className="car-spec-img">  (+234) 08087198712 </span>
          <a className="roundButtonBlackBg" href="tel:+2348087198712" target="_blank"> Call Now </a> 
       </div>
    </div>

    <hr className="footerLine mx-auto d-none d-sm-block"></hr>

    <div className="row linksSection"> 

    <div className="col-12 col-md-5 text-center clearBottom "> 

    <span className="car-spec-img"> <img src={logo} width="25%" className="bright" /> </span>
    <span className="divider"> </span>
    <span className="car-spec-img">  <img src={cmlogo} width="55%"   /> </span>

    <h6 className="text-start clearSpaceTop footerText d-none d-sm-block"> Geely Auto Group is a leading automobile manufacturer based in Hangzhou, China and was founded in 1997 as a subsidiary of Zhejiang Geely Holding Group.
        The group manages several leading brands including Geely Auto, Lynk & Co, Proton Cars, and Geometry.  </h6>
    
    </div>
    <div className="col-12 col-md-7 d-none d-md-block"> 
     <div className="row"> 

     <div className="col-12 col-md-3 offset-md-1"> 
     <div className="linkHeading centerMob"> 
     <p className="footerHeading clearSpaceBottom"> Models </p> 
    </div>
       
    <div className="linkPaths">
     <ul className="paddingOff centerMob"> 
       {vehicleGelly2}
        
        </ul>
         </div>
     </div>

     <div className="col-12 col-md-3"> 
     <div className="linkHeading"> 
     <p className="footerHeading clearSpaceBottom centerMob"> Useful Links</p> 
    </div>
       
    <div className="linkPaths">
     <ul className="paddingOff centerMob"> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a href="/quote" className="link">  Request a Quote </a> </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/news/4"> News </a> </li>
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/contact"> Apply for financing </a> </li>
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/design">   Design Philosophy </a> </li> 
   
          
        </ul>
         </div>
     
     </div>


     <div className="col-12 col-md-3"> 
     <div className="linkHeading"> 
     <p className="footerHeading clearSpaceBottom centerMob"> About Geely</p> 
    </div>
       
    <div className="linkPaths">
     <ul className="paddingOff centerMob"> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/about">  About Geely </a>  </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/contact">   Contact Us </a> </li>
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="https://coscharisgroup.net">  About Coscharis Motors  </a> </li> 
   
        
        </ul>
         </div>
     
     </div>
     
     </div>

    
      
    </div>

<div class="accordion transBg paddingOff d-block d-md-none" id="accordionExample">
  <div class="accordion-item transBg">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button transBg paddingRLOff" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Geely Models
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show transBg" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body transBg">
      <div className="linkPaths">
        <ul className="paddingOff"> 
        {vehicleGelly2}
        </ul>
         </div>  </div>
    </div>
  </div>
  <div class="accordion-item transBg">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button paddingRLOff transBg collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThre" aria-expanded="false" aria-controls="collapseThree">
      Useful Links
      </button>
    </h2>
    <div id="collapseThre" class="accordion-collapse transBg collapse" aria-labelledby="headingThre" data-bs-parent="#accordionExample">
      <div class="accordion-body transBg">
      <div className="linkPaths">
     <ul className="paddingOff"> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a href="/quote">  Request a Quote </a> </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/events/3"> News/Events  </a> </li>
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/contact"> Apply for financing </a> </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/design">  Design Philosophy </a> </li> 
   
        
        </ul>
         </div>
           </div>
    </div>
  </div>
  <div class="accordion-item transBg">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button paddingRLOff transBg collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
       About Geely
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse transBg collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body transBg">
      <div className="linkPaths">
     <ul className="paddingOff"> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/about"> About Geely  </a>  </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="/contact">  Contact Us </a> </li>
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="link" href="https://coscharisgroup.net"> About Coscharis Motors </a>  </li> 
   
        
        </ul>
         </div>
           </div>
    </div>
  </div>

  <div class="accordion-item transBg">
    <h2 class="accordion-header" id="headingFour">
      <button class="accordion-button paddingRLOff transBg collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree">
       Contact Us
      </button>
    </h2>
    <div id="collapsefour" class="accordion-collapse transBg collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body transBg">
      <div className="col-12"> 
        <span className="car-spec-img"> <img src={map} /> </span>
        <span> Km 32 Lekki Epe Expressway, Awoyaya </span>

       </div>
       <div className="col-12">
          <span className="car-spec-img"> <img src={call}  /> </span>
          <span className="car-spec-img">  (+234) 08087198712 </span>
          </div>  </div>
    </div>
  </div>

</div>
  

  
    </div>

 


    </div>
    <div className="signatureSection">
    <div className="col-12 text-center hashBg socialPad"> 
    <a className="link" href="https://www.instagram.com/geelymotorsnigeria"> <FontAwesomeIcon icon={faSquareInstagram}  size="2x" className="mediaIcons" /> </a>
    <a className="link" href="https://www.facebook.com/geelymotorsnigeria">  <FontAwesomeIcon icon={faSquareFacebook}  size="2x" className="mediaIcons"  /> </a>
    </div>
        <div className="clearSpace">
            <div className="col-12 col-md-6 mx-auto"> 
            <div className="signature text-center"> 
            <span className="newsDescriptionText marginOff footerText bottom_footer_link ">   <FontAwesomeIcon icon={faCopyright}/> Geely Motors 2024 </span>
            <span className="newsDescriptionText marginOff footerText"> <a href="#" className="bottom_footer_link link"> Legal Statement </a> <a href="#" className="link"> Privacy Policy </a> </span>
            
           
            </div>
            </div>
            <div className="col-12"> 
            <div className="signature text-center"> 
               <p className="newsDescriptionText marginOff footerText"> Web development by iNspire  </p>
            </div>
            </div>
        </div>
      
    </div>
   </footer>

  </div>

  );
}

export default Footer;
import $ from "jquery";
import warrantyCover from '../images/warranty.jpg';
import tech from '../images/tech.png';
import Nav from './nav.js';
import Footer from './footer.js';



function parts() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row creamBg clearBottomDesktop"> 
           <div className="col-12 text-center warrantyPad"> 
              <h1> Spare Parts </h1>
           </div>
        </div>

      
        <div className="container"> 
         <div className="col-12 warrantyPad text-center"> 
         <p> Discover our spare parts prices for your Geely car by selecting your car model. </p>
    
         </div>

         <div className="col-md-12 mx-auto main clearBottomDesktop1" id="mtBar"> 
                  <div className="col-md-3 text-center mx-auto networkBoxes">
                  <h2> Select Car Model </h2>
           <select class="maintainSelect" aria-label="Default select example">
                  <option selected>Select Model</option>
                  <option value="1">Emgrand</option>
                  <option value="2">Coolray</option>
                  <option value="3">Gxpro</option>
                  <option value="3">Starray</option>

           </select>

          

                  </div>

                  <div className="col-md-2 mx-auto clearSpace networkBoxes"> 
                  <a href type="submit" class="blackBtn">Check Spare Parts Pricing </a>
                  </div>
         



         
         </div>
        </div>

      </div>

   <Footer />

  </div>

  );
}

export default parts;
import $ from "jquery";
import warrantyCover from '../images/warranty.jpg';
import tech from '../images/tech.png';
import Nav from './nav.js';
import Footer from './footer.js';



function warranty() {


   window.scrollTo(0, 0)
   
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row warrantyPad creamBg"> 
           <div className="col-12 text-center"> 
              <h1> Service Warranty </h1>
           </div>
        </div>

        <div className="row clearBottomDesktop1"> 
           <img src={warrantyCover} width="100%" className="paddingOff" />
        </div>

        <div className="container"> 
         <div className="col-12"> 

          <div className="warranty-policy clearBottomDesktop1"> 
             <h5 className="clearBottomDesktop1"> Warranty Policy </h5> 
             <p> Modern technologies, as well as high quality production and components, guarantee the trouble-free operation of GEELY vehicles. Any malfunction or deterioration in consumer properties caused by manufacturing defects will be corrected under the terms of a guarantee within 5 years after purchase or within 150,000 km of run, whichever comes first. <br/> <br/> <br/>

 

Follow the recommendations set out in the car operation manual and service book and you will be able to use GEELY as comfortably as possible. GEELY authorized dealers are certified for professional maintenance, including diagnostic equipment, special tools, original spare parts, and specially trained technical personnel. GEELY provides all necessary support during the ownership period, putting the convenience and satisfaction of the owner as the top priority. </p>
          </div>

          <div className="warranty-benefits clearBottomDesktop1"> 
             <h5 className="clearBottomDesktop1"> Key Benefits of Geely Warranty </h5> 
            <div className="row">

                <div className="col-12 col-md-6 shadow radius flexDisplay benefitsDivPad shortWidth mx-auto"> 
                <figure class="displayBlock marginOff benefitsPad creamBg">
                    <img decoding="async" width="62" height="62" src={tech}/>
                </figure>

                <div class="">
                    <h6 class="">Modern and Advanced Technology</h6>
                    <p class="">GEELY is a global car manufacturer  which offers  its customers modern and high-technology products.</p>
                </div>
                
                </div> 

                <div className="col-12 col-md-6 shadow radius flexDisplay benefitsDivPad mx-auto longWidth"> 
                <figure class="displayBlock marginOff benefitsPad creamBg">
                    <img decoding="async" width="62" height="62" src={tech}/>
                </figure>

                <div class="">
                    <h6 class="">Modern and Advanced Technology</h6>
                    <p class="">GEELY is a global car manufacturer  which offers  its customers modern and high-technology products.</p>
                </div>
                
                </div> 


                <div className="col-12 col-md-6 shadow radius flexDisplay benefitsDivPad shortWidth mx-auto"> 
                <figure class="displayBlock marginOff benefitsPad creamBg">
                    <img decoding="async" width="62" height="62" src={tech}/>
                </figure>

                <div class="">
                    <h6 class="">Modern and Advanced Technology</h6>
                    <p class="">GEELY is a global car manufacturer  which offers  its customers modern and high-technology products.</p>
                </div>
                
                </div> 

                <div className="col-12 col-md-6 shadow radius flexDisplay benefitsDivPad shortWidth mx-auto"> 
                <figure class="displayBlock marginOff benefitsPad creamBg">
                    <img decoding="async" width="62" height="62" src={tech}/>
                </figure>

                <div class="">
                    <h6 class="">Modern and Advanced Technology</h6>
                    <p class="">GEELY is a global car manufacturer  which offers  its customers modern and high-technology products.</p>
                </div>
                
                </div> 
            
            </div>
          </div>

          <div className="clearBottomDesktop clearSpace"> 

          <h5 className="clearBottomDesktop"> General Provisions </h5> 

          <p className="">This warranty applies to GEELY vehicles sold by an official GEELY dealer .</p> 
          </div>

          <div className="flexDisplay warrantyContainer">

             <div className="creamBg warrantyWidth"> 
             <div className="warrantyImage">
             <figure class="elementor-image-box-img">
                <img loading="lazy" decoding="async" width="65" height="65" src={tech} class="attachment-full size-full wp-image-159063" alt=""/>
                </figure>
             </div>
             <div class="elementor-image-box-content">
                <h2 class="elementor-image-box-title">Basic Warranty</h2>
                <p class="elementor-image-box-description">Warranty period for all  parts of GEELY cars,  except those expressly excluded,  is 5 years from the  date of sale or 150,000 km of mileage, whichever comes first. </p>
                </div>
             
             
             </div>
             <div className="creamBg warrantyWidth"> 
             <div className="warrantyImage">
             <figure class="elementor-image-box-img">
                <img loading="lazy" decoding="async" width="65" height="65" src={tech} class="attachment-full size-full wp-image-159063" alt=""/>
                </figure>
             </div>
             <div class="elementor-image-box-content">
                <h2 class="elementor-image-box-title">Basic Warranty</h2>
                <p class="elementor-image-box-description">Warranty period for all  parts of GEELY cars,  except those expressly excluded,  is 5 years from the  date of sale or 150,000 km of mileage, whichever comes first. </p>
                </div>
             
             
             </div>

             <div className="creamBg warrantyWidth"> 
             <div className="warrantyImage">
             <figure class="elementor-image-box-img">
                <img loading="lazy" decoding="async" width="65" height="65" src={tech} class="attachment-full size-full wp-image-159063" alt=""/>
                </figure>
             </div>
             <div class="elementor-image-box-content">
                <h2 class="elementor-image-box-title">Basic Warranty</h2>
                <p class="elementor-image-box-description">Warranty period for all  parts of GEELY cars,  except those expressly excluded,  is 5 years from the  date of sale or 150,000 km of mileage, whichever comes first. </p>
                </div>
             
             
             </div>

             <div className="creamBg warrantyWidth"> 
             <div className="warrantyImage">
             <figure class="elementor-image-box-img">
                <img loading="lazy" decoding="async" width="65" height="65" src={tech} class="attachment-full size-full wp-image-159063" alt=""/>
                </figure>
             </div>
             <div class="elementor-image-box-content">
                <h2 class="elementor-image-box-title">Basic Warranty</h2>
                <p class="elementor-image-box-description">Warranty period for all  parts of GEELY cars,  except those expressly excluded,  is 5 years from the  date of sale or 150,000 km of mileage, whichever comes first. </p>
                </div>
             
             
             </div>
             <div className="creamBg warrantyWidth"> 
             <div className="warrantyImage">
             <figure class="elementor-image-box-img">
                <img loading="lazy" decoding="async" width="65" height="65" src={tech} class="attachment-full size-full wp-image-159063" alt=""/>
                </figure>
             </div>
             <div class="elementor-image-box-content">
                <h2 class="elementor-image-box-title">Basic Warranty</h2>
                <p class="elementor-image-box-description">Warranty period for all  parts of GEELY cars,  except those expressly excluded,  is 5 years from the  date of sale or 150,000 km of mileage, whichever comes first. </p>
                </div>
             
             
             </div>

             <div className="creamBg warrantyWidth"> 
             <div className="warrantyImage">
             <figure class="elementor-image-box-img">
                <img loading="lazy" decoding="async" width="65" height="65" src={tech} class="attachment-full size-full wp-image-159063" alt=""/>
                </figure>
             </div>
             <div class="elementor-image-box-content">
                <h2 class="elementor-image-box-title">Basic Warranty</h2>
                <p class="elementor-image-box-description">Warranty period for all  parts of GEELY cars,  except those expressly excluded,  is 5 years from the  date of sale or 150,000 km of mileage, whichever comes first. </p>
                </div>
             
             
             </div>
          </div>
    
         </div>
        </div>

      </div>

   <Footer />

  </div>

  );
}

export default warranty;
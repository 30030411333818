import $ from "jquery";
import logo from "../images/geely.png";
import Emgrand from '../images/Emgrand.jpg';
import Customer from '../images/contact.png';
import Nav from './nav.js';
import Footer from './footer.js';



function care() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row" id="contactRequestHeight"> 
           <div className="col-12 col-md-9 mx-auto text-center" id="serviceRequestText"> 
              <h1 className="clearBottomDesktop clearTopMob"> CONTACT US </h1>
              <h6 className="clearBottomDesktop d-none d-sm-block"> If you have any questions or queries, we will always be happy to help. Feel free to contact us by telephone or email and we will be sure to get back to you within 24 hours.  </h6>
           </div>
        </div>

        <div className="row"> 
         <div className="col-10 col-md-9 mx-auto" id="formArea"> 
          <div className="row inheritHeight" id="formPlate2"> 
            <div className="d-none d-md-block col-md-2 creamBg text-center" id="crBar"> 
            <img decoding="async" width="56" height="423" src={Customer} class="attachment-full size-full wp-image-221755" alt=""></img>
            </div>
            <div className="col-12 col-md-10 whiteBg formViewPad"> 
                   
            <form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label formFont">Name</label>
    <input type="email" class="form-control form-fields" id="exampleInputEmail1" aria-describedby="emailHelp"/>
    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
  </div>
  <div class="mb-3 row">
    <div className="col-6">
    <label for="exampleInputPassword1" class="form-label  formFont">Email</label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1"/>
    </div>
    <div className="col-6">
    <label for="exampleInputPassword1" class="form-label  formFont">Phone No</label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1"/>
      </div>

  </div>
  <div class="mb-3 row">
    <div className="col-12">
    <label for="exampleInputPassword1" class="form-label  formFont"> Contact Mode </label>
    <select class="form-select form-fields" aria-label="Default select example">
  <option selected>Select Options</option>
  <option value="1"> Email </option>
  <option value="2">Cell </option>
</select>
    </div>

  </div>
  <div class="mb-3">
  <label for="exampleInputEmail1" class="form-label formFont">Complaint</label>
    <input type="email" class="form-control form-fields heightField" id="exampleInputEmail1" aria-describedby="emailHelp"/>
  </div>

  <button type="submit" class="blackBtn">Submit Enquiry</button>
</form>

            
            </div>
          </div>
         </div>
        </div>

      </div>

   <Footer />

  </div>

  );
}

export default care;
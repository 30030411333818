import $ from "jquery";
import logo from "../images/geely.png";
import menu from '../images/menu.png';
import maps from '../images/map.png';
import Nav from './nav.js';
import Footer from './footer.js';
import csrLanding from '../images/csr.jpg';
import eco from '../images/eco.jpg';
import love from '../images/love.jpeg';
import edu from '../images/edu.jpg';



function responsibility() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid paddingOff">
        <div className="row" id="serviceRequestHeightCSR"> 
           <div className="col-12 col-md-9 mx-auto text-center" id="serviceRequestText"> 
              <h1 className="clearBottomDesktop toolsPadding">Geely Corporate Social Responsibility</h1>
              <h6 className="clearBottomDesktop visionPadding"> Zhejiang Geely Holding Group (ZGH) believes that the formation, development, and continued operation of an enterprise is not only to 
              produce economic value, but also to create social value and fulfil its social responsibilities. Only with both taken into consideration can enterprises develop in a sustainable way.

              </h6>
              <div className="row"> 
         <div className="col-10 col-md-12 mx-auto" id="formArea"> 
         <img src={csrLanding} width="" height="362" className="img-fluid csrHero" />
         </div>
        </div>
           </div>
        </div>

    
                <div className="col-md-12 mx-auto" id="serviceRequestText1"> 

                <div className="row" id="serviceRequestText2"> 

                <div className="col-12 col-md-5 text-center">

                    <img src={eco} width="408" height="454" className="img-fluid" /> 
                
                </div>
                <div className="col-12 col-md-7" id="serviceRequestText1"> 
                <h2 class="clearSpaceBottom mobTextCenter visionPadding">Making the safest, most eco-friendly and energy-efficient cars</h2>
                <p className="mobTextCenter visionPadding">In order to develop sustainably, enterprises must go beyond just complying with laws and regulations. They need to implement high standards of ethics throughout their operation and give back to society and their customers. Giving back to society and customers, ZGH has developed a social responsibility strategy that is integrated throughout the group’s operations which lays the foundation for the group to become a globally respected enterprise.</p>
                </div>
                
                </div>
        
        </div>


        <div className="col-12" id="loveSection"> 
           <div className="textPosition text-center"> 
           <h2 className="white-text" id="textDesign"> Let the world feel love </h2>
            </div>
          
         
        </div>

        <div className="col-md-12 mx-auto creamBg" id="serviceRequestText1"> 

<div className="row" id=""> 

<div className="col-12 col-md-7" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter">Geely Social Welfare</h2>
<p className="mobTextCenter visionPadding">Geely vision for social welfare is to “Let the world feel love” with a focus on education and environmental protection.  Our social welfare projects cover three areas of public interest – culture, poverty alleviation, 
    and support for the underprivileged. Our targeted social welfare projects follow four core principles in order to solve societal issues, discovering the root of the problem, achieving community integration, promoting 
    the in-depth participation of stakeholders, and developing a sustainable social welfare model. </p>
</div>

<div className="col-12 col-md-5 text-center">

    <img src="https://www.geelyksa.com/wp-content/uploads/2022/02/Page-6-5-408x454-1.jpg.webp" width="408" height="454" className="img-fluid" /> 

</div>


</div>

</div>

   
<div className="col-md-12 mx-auto" id="serviceRequestText1"> 

<div className="row" id=""> 

<div className="col-12 col-md-5 text-center">

    <img src={edu} width="408" height="454" className="img-fluid" /> 

</div>
<div className="col-12 col-md-7" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter">Education & Development</h2>
<p className="mobTextCenter visionPadding">Geely HOPE – Green Pathways program has been operational since 2014 working hard to help youth and students in underprivileged areas across China to gain access to a fair education system and provide opportunities for development and professional grooming.

Since 2014, the “Geely HOPE – Green Pathways” program has been helping youths and outstanding students in underprivileged areas all around China obtain fair education and development opportunities.

Geely Hope – Green Pathways program has already provided funds to schools and students in multiple areas. The funds provided by Geely have been utilized for sports equipment, Physical education courses, setting-up recruitment centers for a national volunteering program to help underprivileged youth, and bringing a ray of hope to thousands
 of underprivileged youth by offering them the opportunity to fulfill their potential 
 and pursue their dreams.</p>
</div>

</div>

</div>

      

      </div>

   <Footer />

  </div>

  );
}

export default responsibility;
import $ from "jquery";
import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from "../images/geely.png";
import menu from '../images/menu.png';
import maps from '../images/map.png';
import Nav from './nav.js';
import Footer from './footer.js';
import csrLanding from '../images/csr.jpg';
import eco from '../images/eco.jpg';
import love from '../images/love.jpeg';
import edu from '../images/edu.jpg';
import vision from '../images/vision.jpeg';
import gv from '../images/gv.jpg';
import tech from '../images/tech.jpg';
import dev from '../images/dev.jpg';
import { Form } from "react-router-dom";
import power from '../images/power.jpg' 




function history() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
      <div className="row warrantyPad creamBg"> 
           <div className="col-12 text-center"> 
              <h1> HISTORY </h1>
           </div>
        </div>
       
           <div className="col-12" id=""> 
           <div className="row" id="serviceRequestHeight1"> 
           <div className="col-12 col-md-6 mx-auto clearSpaceCarouselVideo clearSpaceCarouselBottomVideo text-center" id="video"> 
           <iframe class="elementor-video-iframe" allowfullscreen="" allow="clipboard-write" title="vimeo Video Player" src="https://player.vimeo.com/video/681192027?autoplay=1&amp;playsinline=1&amp;color&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=0&amp;portrait=0&amp;byline=0#t="></iframe>
         </div>

         <div className="col-12 col-md-6 clearSpace" id="serviceRequestText2"> 

              <h1 className="clearBottomDesktop vidTitle mobTextCenter toolsPadding"> Geely Auto 1.0 To 4.0 Era </h1>
              <h6 className="clearBottomDesktop mobTextCenter"> Making globally focused cars requires factories that are consistent in their practices which is why Geely Auto has moved
               towards implementing Industry 4.0 practices in all of its new facilities that produce CMA based cars.  </h6>
      
        </div>
           </div>
        </div>

    
                <div className="col-md-12 mx-auto" id="serviceRequestText1"> 

                <div className="row" id=""> 

                <div className="col-12 col-md-5 text-center">

                    
                
                </div>
                <div className="col-12 col-md-7" id="serviceRequestText1"> 
               
                </div>
                
                </div>
        
        </div>
          
   
 

      </div>

      <Footer/>
  </div>

  );
}

export default history;
import $ from "jquery";
import logo from "../images/geely.png";
import menu from '../images/menu.png';
import maps from '../images/map.png';
import Nav from './nav.js';
import Footer from './footer.js';
import csrLanding from '../images/csr.jpg';
import eco from '../images/eco.jpg';
import love from '../images/love.jpeg';
import edu from '../images/edu.jpg';     



function research() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid paddingOff">
        <div className="" id="rdbg"> 
           <div className="col-12 col-md-9 mx-auto text-center" id="serviceRequestTextrd"> 
              <h1 className="clearBottomDesktop toolsPadding whiteText">Research & Development</h1>
           </div>
        </div>



        <div className="rdStatement marginTopStandard text-center"> 
             <h2> Geely Auto Pillars of Development </h2>

             <div className="marginTopStandard col-11 col-md-7 mx-auto" > 
             <p> 		Create an exceptional mobility experience means making high-quality and high-value autos with the latest technology. 
                To do so, Geely has set up a globalized R&D network with over 10,000 professionals focused on science and technology.	</p>

                <p> 		Geely’s dedication to R&D has been able to achieve over 14,000 patents 
                    and recognition as one the China’s top ten brands for intellectual property and independent innovation.	</p>

                    <p> 		Geely innovation centers are not limited to research and development but each individual has a keen interest in 
                        improving the quality of the products and the production process.	</p>

             </div> 
        </div>


        <div className="col-md-12 mx-auto creamBg marginTopStandard" id="serviceRequestText1"> 

<div className="row" id=""> 

<div className="col-12 col-md-6 text-center">

    <img src="https://www.geelyksa.com/wp-content/uploads/2022/02/RD-Safety.png.webp" width="608" height="654" className="img-fluid" /> 

</div>
<div className="col-12 col-md-5" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter">Safety</h2>
<p className="mobTextCenter visionPadding">Geely provides everyone an opportunity to drive a refined car. This can only be achieved by moving towards a universal modular scalable
 platform that allows developing architectures and new technologies faster and cost-effectively. </p>
</div>




</div>

</div>

    
                <div className="col-md-12 mx-auto" id="serviceRequestText1"> 

                <div className="row" id="serviceRequestText2"> 

             
                <div className="col-12 offset-md-1 col-md-5" id="serviceRequestText1"> 
                <h2 class="clearSpaceBottom mobTextCenter visionPadding">Intec</h2>
                <p className="mobTextCenter visionPadding">Safety tech is one of the four main pillars for our vehicle development. This is the reason Geely can make the safest vehicles for you and the pedestrians around your vehicle. 
                Since Geely considers your protection as the first and foremost concern, eventually safety becomes an important goal.</p>
                </div>

                <div className="col-12 col-md-6 text-center">

<img src="https://www.geelyksa.com/wp-content/uploads/2022/03/2.jpg.webp" width="608" height="654" className="img-fluid" /> 

</div>
                
                </div>
        
        </div>


<div className="col-md-12 mx-auto creamBg" id="serviceRequestText1"> 

<div className="row" id=""> 
<div className="col-12 col-md-6 text-center">

    <img src="https://www.geelyksa.com/wp-content/uploads/2022/01/universal-Platform.jpg.webp" width="608" height="654" className="img-fluid" /> 

</div>
<div className="col-12 col-md-5" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter">Universal Platform </h2>
<p className="mobTextCenter visionPadding">To create a refined car for everyone, Geely has created a universal platform.
 In order to develop new technologies more rapidly and economically, universal modular and flexible platforms and architectures should be adopted, so that everyone can drive a refined car. </p>
</div>




</div>

</div>

    
                <div className="col-md-12 mx-auto" id="serviceRequestText1"> 

                <div className="row" id="serviceRequestText2"> 

            
                <div className="col-12 offset-md-1 col-md-5" id="serviceRequestText1"> 
                <h2 class="clearSpaceBottom mobTextCenter visionPadding">Safety First</h2>
                <p className="mobTextCenter visionPadding">
The most precious gift of all is human life, and the Geely team is dedicated to protecting it first and foremost. In regard to the safety of our drivers and passengers, as well as pedestrians, Geely has set a goal to make the safest car possible. 
Towards that goal, safety technology has been positioned as one of our four main development pillars.</p>
                </div>

<div className="col-12 col-md-6 text-center">

<img src="https://www.geelyksa.com/wp-content/uploads/2022/01/safety-first.jpg.webp" width="608" height="654" className="img-fluid" /> 

</div>
                
                </div>
        
        </div>


<div className="col-md-12 mx-auto creamBg" id="serviceRequestText1"> 

<div className="row" id=""> 

<div className="col-12 col-md-6 text-center">

    <img src="https://www.geelyksa.com/wp-content/uploads/2022/01/energy.jpg.webp" width="608" height="654" className="img-fluid" /> 

</div>

<div className="col-12 col-md-5" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter">Energy Diversification </h2>
<p className="mobTextCenter visionPadding">As the world endeavors to reduce emissions, conserve energy, and improve sustainability, new energy is needed in cars. 
Geely is heavily invested in the development of new energy vehicles and sees them as the future of the industry. </p>
</div>




</div>

</div>

    
<div className="col-md-12 mx-auto" id="serviceRequestText1"> 

                <div className="row" id="serviceRequestText2"> 

                <div className="col-12 offset-md-1 col-md-5" id="serviceRequestText1"> 
                <h2 class="clearSpaceBottom mobTextCenter visionPadding">Automotive Intelligence</h2>
                <p className="mobTextCenter visionPadding">
                Through technological advances, people have become more connected, and so have automobiles. 
                Geely has placed great importance in the area of automobiles since the industry is moving in the direction of smarter connected cars.</p>
                </div>


                <div className="col-12 col-md-6 text-center">

                    <img src="https://www.geelyksa.com/wp-content/uploads/2022/01/intelligence.jpg.webp" width="608" height="654" className="img-fluid" /> 
                
                </div>
                
                </div>
        
        </div>
      

      </div>

   <Footer />

  </div>

  );
}

export default research;
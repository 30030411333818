import $ from "jquery";
import logo from "../images/geely.png";
import menu from '../images/menu.png';
import maps from '../images/map.png';
import Nav from './nav.js';
import Footer from './footer.js';
import csrLanding from '../images/design.jpg';
import eco from '../images/eco.jpg';
import love from '../images/love.jpeg';
import edu from '../images/edu.jpg';



function design() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid paddingOff">
        <div className="" id="serviceRequestHeightDesign"> 
           <div className="col-12 col-md-9 mx-auto text-center" id="serviceRequestTextrd"> 
              <h1 className="clearBottomDesktop toolsPadding"> Design Philosophy </h1>
              <h6 className="clearBottomDesktop visionPadding"> 
              Geely Auto should look at global trends but our cars should also have a hint of where the company originates from.
              </h6>
              <div className="row"> 
         <div className="col-10 col-md-12 mx-auto halfHeight" id="video"> 
       
           <iframe class="elementor-video-iframe img-fluid" allowfullscreen="" allow="clipboard-write" title="vimeo Video Player" src="https://player.vimeo.com/video/681192027?autoplay=1&amp;playsinline=1&amp;color&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=0&amp;portrait=0&amp;byline=0#t="></iframe>
      

         </div>
        </div>
           </div>
        </div>


    
                <div className="col-md-12 mx-auto greyBg" id="serviceRequestText1"> 

          
        <div className="rdStatement marginTopStandard text-center"> 
             <h2 className="marginTopStandard"> Personality Driven Cars </h2>

             <div className="marginTopStandard col-11 col-md-7 mx-auto" > 
             <p> 		A vehicle is not only bits and pieces of metal, plastic, and rubber. It is a sum of ideas that originated as a result of a common problem, the people who designed it, and the people who produced it.
	                         </p>

                <p> 		Great cars have great designs and it is our job at Geely Design to make it a reality by working hand in hand with our engineers.	</p>

             </div> 
        </div>
        
        </div>


        <div className="col-md-12 mx-auto" id="serviceRequestTextrd"> 

<div className="row" id=""> 

<div className="col-12 col-md-5 text-end">

<img decoding="async" width="408" height="454" src="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_Manufacturing_img.jpg.webp" class="attachment-large size-large wp-image-13317 img-fluid" alt="" srcset="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_Manufacturing_img.jpg.webp 408w,https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_Manufacturing_img-270x300.jpg.webp 270w" sizes="(max-width: 408px) 100vw, 408px"/>

</div>
<div className="col-12 col-md-5" id="serviceRequestTextDesign"> 
<h2 class="clearSpaceBottom mobTextCenter visionPadding">Manufacturing facilities of Geely Holding Group are represented by 18 factories</h2>
<p className="mobTextCenter visionPadding">Geely Auto Group has powertrain and vehicle manufacturing facilities all over China in 18 different factories located in Taizhou, Ningbo, Chengdu, Xiangtan, Baoji, and Jinzhong.</p>

<p> The group employs over 50,000 workers and operates from five global research centers in Hangzhou, Ningbo, Gothenburg, Coventry, and Frankfurt. The group also features five design studios with over 1000 staff members globally. Geely design studios are located in Shanghai, Gothenburg, Barcelona, California, and Coventry. </p>

<p> The facilities are capable of producing a wide range of car models including Sedans, MPVs, SUVs, and Crossovers.</p>
</div>

</div>

</div>


        <div className="col-12"> 
        <div className="textPosition1 text-center"> 
           <h1 className="white-text" id="textDesign"> Design Philosophy </h1>
           <h6 className="white-text"> For many years, Geely brand is committed to the same principle: overcoming the frontiers. </h6>
            </div>
        <div class="elementor-background-video-container" data-vimeo-initialized="true">
													<div class="elementor-background-video-embed"></div>
												<iframe src="https://player.vimeo.com/video/691717615?muted=1&amp;autoplay=1&amp;dnt=1&amp;loop=1&amp;background=1&amp;app_id=122963" width="426" height="178" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Design Philiosphy_fixed_1" data-ready="true" class="elementor-background-video-embed" style={{width: "",height: "50vh"}}></iframe></div>
        
          
         
        </div>

        <div className="col-md-12 mx-auto" id="serviceRequestText1"> 

<div className="row" id=""> 

<div className="col-12 offset-md-2 col-md-5" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter">Refined Design; Refined Cars</h2>
<p className="mobTextCenter visionPadding">
Cars are moving marvels of art and they must have a unique presence that invokes a certain feeling of joy and ownership in the eye of the beholder. </p>
</div>

<div className="col-12 col-md-5 text-start">

<img loading="lazy" decoding="async" width="408" height="454" src="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_design_image.jpg.webp" class="attachment-large size-large wp-image-13319 img-fluid" alt="" srcset="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_design_image.jpg.webp 408w,https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_design_image-270x300.jpg.webp 270w" sizes="(max-width: 408px) 100vw, 408px"/>

</div>


</div>




</div>



<div className="col-md-12 mx-auto greyBg" id="serviceRequestTextrd"> 

          
<div className="rdStatement marginTopStandard text-center"> 
     <h2 className="marginTopStandard"> Gallery </h2>

     <div className="marginTopStandard col-12  col-md-10 mx-auto" > 
  
        <div className="row"> 

        <div className="col-12 col-md-5 text-end"> 
        <img loading="lazy" decoding="async" width="492" height="487" src="https://www.geelyksa.com/wp-content/uploads/2022/04/img-1-square.jpg.webp" class="attachment-full size-full wp-image-21267 img-fluid" alt="" srcset="https://www.geelyksa.com/wp-content/uploads/2022/04/img-1-square.jpg.webp 492w,https://www.geelyksa.com/wp-content/uploads/2022/04/img-1-square-300x297.jpg.webp 300w" sizes="(max-width: 492px) 100vw, 492px"/>
        
        </div>

        <div className="col-12 col-md-6"> 
        <div className="row"> 
        <div className="col-6"> 
         <img loading="lazy" decoding="async" width="300" height="229" src="https://www.geelyksa.com/wp-content/uploads/2022/04/img-1-small.jpg.webp" class="attachment-full size-full wp-image-21269 img-fluid" alt=""></img>
         </div>
         <div className="col-6"> 
         <img loading="lazy" decoding="async" width="300" height="229" src="https://www.geelyksa.com/wp-content/uploads/2022/04/img-2-small.jpg.webp" class="attachment-full size-full wp-image-21271 img-fluid" alt=""></img>
         </div>
        </div>

        <div className="col-12 marginTopStandard1"> 
        <img loading="lazy" decoding="async" width="631" height="237" src="https://www.geelyksa.com/wp-content/uploads/2022/04/img-1-rect.jpg.webp" class="attachment-full size-full wp-image-21273 img-fluid" alt="" srcset="https://www.geelyksa.com/wp-content/uploads/2022/04/img-1-rect.jpg.webp 631w,https://www.geelyksa.com/wp-content/uploads/2022/04/img-1-rect-300x113.jpg.webp 300w" sizes="(max-width: 631px) 100vw, 631px"/>
         </div>
   
        </div>
        
        </div>

     </div> 
</div>

</div>
   
<div className="col-12 col-md-9 mx-auto text-center" id="serviceRequestTextrd"> 
<h2 class="mobTextCenter"> GEELY AUTO BRAND ATTRIBUTES </h2>
<div className="row" id=""> 

<div className="col-12 col-md-6" id="serviceRequestTextrd"> 

<div class="card cardDesign marginOff">
<img loading="lazy" decoding="async" width="538" height="297" src="https://www.geelyksa.com/wp-content/uploads/2022/02/page-numbers-updated.jpg.webp" class="attachment-full size-full wp-image-18317 cardImg img-fluid" alt="" srcset="https://www.geelyksa.com/wp-content/uploads/2022/02/page-numbers-updated.jpg.webp 548w,https://www.geelyksa.com/wp-content/uploads/2022/02/page-numbers-updated-300x163.jpg.webp 300w" sizes="(max-width: 548px) 100vw, 548px"/>
  <div class="card-body">
  <div class="elementor-image-box-content text-center"><h3 class="elementor-image-box-title">Approachable</h3><p class="elementor-image-box-description">Geely Auto is an independent and authentic Chinese brand that is able to succeed on a global basis. </p></div>
  </div>
</div>
</div>

<div className="col-12 col-md-6" id="serviceRequestTextrd"> 

<div class="card cardDesign marginOff">
<img loading="lazy" decoding="async" width="538" height="297" src="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_bright.jpg.webp" class="attachment-full size-full wp-image-13330 img-fluid" alt="" srcset="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_bright.jpg.webp 548w,https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_bright-300x163.jpg.webp 300w" sizes="(max-width: 548px) 100vw, 548px"/>  <div class="card-body">
  <div class="elementor-image-box-content text-center"><h3 class="elementor-image-box-title">Bright</h3><p class="elementor-image-box-description">We are at the forefront of the Chinese entrepreneurial mindset; we are optimistic about the future and have the will to succeed. </p></div>
  </div>
</div>
</div>

<div className="col-12 col-md-6" id="serviceRequestTextrd"> 

<div class="card cardDesign marginOff">
<img loading="lazy" decoding="async" width="538" height="297" src="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_stunning.jpg.webp" class="attachment-full size-full wp-image-13332 img-fluid" alt="" srcset="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_stunning.jpg.webp 548w,https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_stunning-300x163.jpg.webp 300w" sizes="(max-width: 548px) 100vw, 548px"/> <div class="card-body">
  <div class="elementor-image-box-content text-center"><h3 class="elementor-image-box-title">STUNNING</h3><p class="elementor-image-box-description">
We benchmark against global designs with refinements that show a hint of where we come from. We’re not making global cars; we’re taking Chinese cars global.</p></div>
  </div>
</div>
</div>


<div className="col-12 col-md-6" id="serviceRequestTextrd"> 

<div class="card cardDesign marginOff">
<img loading="lazy" decoding="async" width="538" height="297" src="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_surprising.jpg.webp" class="attachment-full size-full wp-image-13326 img-fluid" alt="" srcset="https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_surprising.jpg.webp 548w,https://www.geelyksa.com/wp-content/uploads/2022/02/design-philosophy_surprising-300x163.jpg.webp 300w" sizes="(max-width: 548px) 100vw, 548px"/> <div class="card-body">
  <div class="elementor-image-box-content text-center"><h3 class="elementor-image-box-title">SURPRISING</h3><p class="elementor-image-box-description">
The latest technology should not be associated with the price tag, we believe that technology should be accessible to all and integrated seamlessly into the user’s experience. </p></div>
  </div>
</div>
</div>

</div>

</div>

      

      </div>

   <Footer />

  </div>

  );
}

export default design;
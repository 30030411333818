import $ from "jquery";
import React, { useState, useEffect, Component } from 'react';
import warrantyCover from '../images/warranty.jpg';
import tech from '../images/tech.png';
import maps from '../images/cg-map.png';
import Nav from './nav.js';
import Footer from './footer.js';
import map from '../images/map.png';
import directions from '../images/network_direction.png';
import {
  InMemoryCache,
  ApolloClient,
  gql,
} from '@apollo/client';

const client = new ApolloClient({
  cache:  new InMemoryCache(),
  uri: "https://geely-cms.cs-labs.online/graphql"
});


function Network() {

window.scrollTo(0, 0)


const [contactList, setContactList ] = useState([])
const [locationCounter, setLocationCounter] = useState([])


let contactArray = []
let locationCount
useEffect( () => {
client
.query({
query: gql`
 query{
  pages {
    edges {
      node {
        contact {
          contact {
            maps
            citytag
            closingTime
            address
            locationImage {
              mediaItemUrl
            }
            phoneNumber
            openingTime
          }
        }
      }
    }
  }
}
`,
})
.then(async result => {
console.log(result)
let contactDetailPack 


let x
locationCount = result.data.pages.edges[0].node.contact.contact.length

//alert(result.data.vehicles.edges.length)

if (contactArray.length != result.data.pages.edges[0].node.contact.contact.length){
for(x=0; x < result.data.pages.edges.length; x++){ 
     
     contactDetailPack = [{tag:result.data.pages.edges[0].node.contact.contact[x].citytag , map:result.data.pages.edges[0].node.contact.contact[x].maps, address:result.data.pages.edges[0].node.contact.contact[x].address, locationImage:result.data.pages.edges[0].node.contact.contact[x].locationImage.mediaItemUrl, opening:result.data.pages.edges[0].node.contact.contact[x].openingTime, closing:result.data.pages.edges[0].node.contact.contact[x].closingTime, phone:result.data.pages.edges[0].node.contact.contact[x].phoneNumber,  }]

     contactArray.push(contactDetailPack)
 
      
}
console.log(contactArray)

setContactList(contactArray)
setLocationCounter(locationCount)
}


});

}, [])   






let networkDetailsPack
if(contactList.length > 0){ 

  networkDetailsPack = contactList.map((locations, i) => 

    <div className={`row clearSpaceBottom ${i}`}> 
    <div className="col-12 col-md-4 paddingOff text-center"> 
      <img src={locations[0].locationImage} width="410" height="250" className="geelyMap "/>
   
     <h6 className="clearSpace">   <a className="link" href={locations[0].map} target="_blank" > <img src={directions} width="" height="" /> Get Directions   </a> </h6> 
  
     
    </div>

    <div className="col-12 col-md-4 networkBox creamBg"> 
   
     <h4>  <img src={map}/> Address </h4>

     <ul>
<li>{locations[0].address}</li>

<li>Branch Phone Number: <a href={`tel:${locations[0].phone}`}><strong>{locations[0].phone}</strong></a></li>

</ul>


    
    </div>

    <div className="col-12 col-md-4 networkBox1"> 
      
    <h4>  <img src={map}/> Working Hours</h4>
    <ul>
 <li>Monday to Friday</li>
 <li>From <b>{locations[0].opening}</b> To <b>{locations[0].closing}</b> </li>

         </ul>

    
    </div>


</div>
  

)


}
  

function segregateNetwork(e){ 
 
    let currentValue = e.target.value
    let element

  
    if(currentValue === "1"){ 

       document.getElementsByClassName('3')[0].style.display = "none"

      document.getElementsByClassName('0')[0].style.display = "flex"
      document.getElementsByClassName('1')[0].style.display = "flex"
      document.getElementsByClassName('2')[0].style.display = "flex"
    
    }

    if(currentValue === "2"){

  
      document.getElementsByClassName('0')[0].style.display = "none"
      document.getElementsByClassName('1')[0].style.display = "none"
      document.getElementsByClassName('2')[0].style.display = "none"
      document.getElementsByClassName('3')[0].style.display = "flex"


    }

    if(currentValue === "0"){

      document.getElementsByClassName('0')[0].style.display = "flex"
      document.getElementsByClassName('1')[0].style.display = "flex"
      document.getElementsByClassName('2')[0].style.display = "flex"
      document.getElementsByClassName('3')[0].style.display = "flex"

      
    }


}


  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row creamBg clearBottomDesktop"> 
           <div className="col-12 text-center warrantyPad"> 
              <h1> Geely Nigeria Network </h1>
           </div>
        </div>

      
        <div className="container"> 
         <div className="col-12 warrantyPad text-center"> 
        
         </div>
            
      
         <div className="col-md-12 mx-auto main clearBottomDesktop1" id="mtBar"> 
             
         <div className="col-12 servicePad text-center"> 
         <h3> FIND NETWORK BY LOCATION</h3>
         </div>
          <div className="col-12 col-md-6 mx-auto networkBoxes"> 

          <div className="row warrantyPad">

    <div className="col-12 col-md-6 mx-auto text-center networkBoxesBelow">
      <select onChange={segregateNetwork} class="maintainSelect" aria-label="Default select example">
      <option value="0" selected>Select City</option>
      <option value="1">Lagos</option>
      <option value="2">Abuja</option>
     </select>
      </div>
    


    </div>

          
          </div>


         



         
         </div>

         <div className="row"> 
           <div className="col-12"> 
             <h4> ({locationCounter}) locations Nationwide </h4>

             <hr></hr>
           </div>
         </div>


         <div className="clearSpaceTop"> 
           <div className="col-12"> 
             <h4> Certified Showroom and Service Centers </h4>
           </div>

           {networkDetailsPack}

         </div>

        </div>

      </div>

   <Footer />

  </div>

  );

}

export default Network;
import $ from "jquery";
import logo from "../images/geely.png";
import Emgrand from '../images/Emgrand.jpg';
import Customer from '../images/customer.png';
import Nav from './nav.js';
import Footer from './footer.js';



function care() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row" id="careRequestHeight"> 
           <div className="col-12 text-center" id="serviceRequestText"> 
              <h1 className="clearBottomDesktop clearTopMob"> Customer Care </h1>
              <h6 className="clearBottomDesktop d-none d-sm-block"> Geely Auto loves to hear back from you. Leave us a message and we will cater to your concerns very soon.  </h6>
           </div>
        </div>

        <div className="row"> 
         <div className="col-10 col-md-9 mx-auto" id="formArea"> 
          <div className="row inheritHeight" id="formPlate1"> 
            <div className="d-none d-md-block col-md-2 creamBg text-center" id="crBar"> 
            <img decoding="async" width="36" height="600" src={Customer} class="attachment-full size-full wp-image-221755" alt=""></img>
            </div>
            <div className="col-12 col-md-10 whiteBg formViewPad"> 
                   
            <form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label formFont">Name</label>
    <input type="email" class="form-control form-fields" id="exampleInputEmail1" aria-describedby="emailHelp"/>
    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
  </div>
  <div class="mb-3 row">
    <div className="col-6">
    <label for="exampleInputPassword1" class="form-label  formFont">Email</label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1"/>
    </div>
    <div className="col-6">
    <label for="exampleInputPassword1" class="form-label  formFont">Phone No</label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1"/>
      </div>

  </div>
  <div class="mb-3 row">
    <div className="col-12">
    <label for="exampleInputPassword1" class="form-label  formFont">Type</label>
    <select class="form-select form-fields" aria-label="Default select example">
  <option selected>Select Options</option>
  <option value="1">Emgrand</option>
  <option value="2">Coolray</option>
  <option value="3">Gxpro</option>
  <option value="3">Starray</option>

</select>
    </div>

  </div>
  <div class="mb-3">
  <label for="exampleInputEmail1" class="form-label formFont">Complaint</label>
    <input type="email" class="form-control form-fields" id="exampleInputEmail1" aria-describedby="emailHelp"/>
  </div>

  <button type="submit" class="blackBtn">Submit Enquiry</button>
</form>

            
            </div>
          </div>
         </div>
        </div>

      </div>

   <Footer />

  </div>

  );
}

export default care;
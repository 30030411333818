import $ from "jquery";
import logo from "../images/geely.png";
import menu from '../images/menu.png';
import maps from '../images/map.png';
import { Link} from "react-router-dom";



function navigation() {


  function navFunction(){
   document.getElementById("serviceTab").style.display = 'block'

  }

  function navFunction1(){
    document.getElementById("serviceTab").style.display = 'none'
 
   }


  function navFunction3(){
    document.getElementById("aboutTab").style.display = 'block'
 
   }
 
   function navFunction4(){
     document.getElementById("aboutTab").style.display = 'none'
  
    }

    function navFunction5(){
      document.getElementById("innovationTab").style.display = 'block'
   
     }
   
     function navFunction6(){
       document.getElementById("innovationTab").style.display = 'none'
    
      }

      function navFunction7(){
        document.getElementById("mediaTab").style.display = 'block'
     
       }
     
       function navFunction8(){
         document.getElementById("mediaTab").style.display = 'none'
      
        }
  
  return (
  <div> 

<div data-pushbar-id="mypushbar2" data-pushbar-direction="right" className="sideNav"> 

<div class="side_bar_close" data-pushbar-close="">
								<img width="19" height="18" src="https://www.geelyksa.com/wp-content/uploads/2021/12/side_bar_close.png.webp" alt=""/>
							</div>





								<div class="side_bottom_block">
								<ul class="social_links">
									<li><a href="https://www.facebook.com/geelymotorsnigeria" target="_blank"><img width="10" height="19" src="https://www.geelyksa.com/wp-content/uploads/2023/04/side_bar_fb-bl.png.webp"/></a></li>
									<li><a href="https://www.instagram.com/geelymotorsnigeria" target="_blank"><img width="17" height="17" src="https://www.geelyksa.com/wp-content/uploads/2023/04/side_bar_insta.png.webp"/></a></li>
										</ul>
								<span class="text">© Copyright 2024 Geely All rights reserved.</span>
							</div>

</div>

<div data-pushbar-id="mypushbar1" data-pushbar-direction="right" className="sideNav"> 

<div class="side_bar_close" data-pushbar-close="">
								<img width="19" height="18" src="https://www.geelyksa.com/wp-content/uploads/2021/12/side_bar_close.png.webp" alt=""/>
							</div>

<div class="shopping_tool_bg">
				<div class="side_inner_block shopping_tool">
							<div className="desktopSideNav">
									<h2 class="title sideNavButtonContainer">Shopping Tools</h2>

									<ul class="tools_list">
										<li>
											<a href="#"> <span class="tool_img"></span><img width="23" height="21" src="https://www.geelyksa.com/wp-content/uploads/2021/11/soffers.png.webp" alt=""/> <span class="tool_list_text">Special Offers</span></a>
										</li>
										<li>
											<a href="/media"> <span class="tool_img"></span><img width="20" height="20" src="https://www.geelyksa.com/wp-content/uploads/2021/11/imageandvideos.png.webp" alt=""/> <span class="tool_list_text">Image and Videos</span></a>
										</li>
										<li>
											<a href="/testdrive"> <span class="tool_img"></span><img width="21" height="21" src="https://www.geelyksa.com/wp-content/uploads/2021/11/testadrive.png.webp" alt=""/> <span class="tool_list_text">Request a Test Drive</span></a>
										</li>
										<li>
											<a href="/quote"> <span class="tool_img"></span><img width="20" height="20" src="https://www.geelyksa.com/wp-content/uploads/2021/11/requestaqoute.png.webp" alt=""/> <span class="tool_list_text">Request a Quote</span></a>
										</li>
										<li>
											<a href="/contact"> <span class="tool_img"></span><img width="22" height="22" src="https://www.geelyksa.com/wp-content/uploads/2021/11/contactus.png.webp" alt=""/> <span class="tool_list_text">Contact Us</span></a>
										</li>
									</ul>						
                             </div>

                         <div className="mobileSideNav">
						<h2 class="title sideNavButtonContainer"> GEELY </h2>
						<div class="accordion transBg paddingOff" id="accordionExample">
  <div class="accordion-item transBg1 ">
    <h2 class="accordion-header sideNavButtonContainer" id="headingOne">
      <button class="accordion-button transBg paddingRLOff" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       About Geely
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show transBg" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body creamBg">
     
        <ul className="paddingOff sideNavButtonContainerInner"> 
          <li className="newsDescriptionTex clearSpaceBottomFooter">  <a className="sideNavButton" href="/about"> Our Vision </a>     </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/history"> Our History </a>  </li>
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/csr"> Corporate Social Responsibility </a>       </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href=" "> Geely Events </a>    </li> 
        </ul>
      </div>
    </div>
  </div>
  <div class="">
    <h2 class="sideNavButtonContainer" id="headingThree">
      <a href="/vehicles" className="sideNavButton paddingRLOff transBg" data-bs-target="#collapseThre" aria-expanded="false" aria-controls="collapseThree">
      Vehicles
      </a>
    </h2>
  
  </div>
  <div class="accordion-item transBg1">
    <h2 class="accordion-heade sideNavButtonContainer" id="headingThree">
      <button class="accordion-button paddingRLOff transBg collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
       Innovation
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse transBg collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body creamBg">
      <div className="linkPaths">
     <ul className="sideNavButtonContainerInner"> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/design"> Design Philosophy </a>  </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/research"> Research and Development </a>   </li>     
        </ul>
         </div>


           </div>
    </div>
  </div>

  <div class="">
    <h2 class="sideNavButtonContainer" id="headingThree">
      <a href="#" class="sideNavButton paddingRLOff transBg" data-bs-target="#collapseThre" aria-expanded="false" aria-controls="collapseThree">
      Offers
      </a>
    </h2>
  
  </div>

  <div class="accordion-item transBg1">
    <h2 class="accordion-header sideNavButtonContainer" id="headingFour">
      <button class="accordion-button paddingRLOff transBg collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree">
       Services
      </button>
    </h2>
    <div id="collapsefour" class="accordion-collapse transBg collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body creamBg">
	  <ul className="sideNavButtonContainerInner"> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/network">  Service Network </a>  </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/bookings">  Service Request </a> </li>
		  <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/warranty">  Service Warranty </a> </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/maintainance">  Periodic Maintenance </a> </li>  
		  <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/parts">  Spare Parts </a></li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/customer-care">  Customer Care </a> </li>       
        </ul>
       </div>
    </div>
  </div>

  <div class="accordion-item transBg1">
    <h2 class="accordion-header sideNavButtonContainer" id="">
      <button class="accordion-button paddingRLOff transBg collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Media
      </button>
    </h2>
    <div id="collapseFive" class="accordion-collapse transBg collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div class="accordion-body creamBg">
	  <ul className="sideNavButtonContainerInner"> 
          <li className="newsDescriptionText clearSpaceBottomFooter"><a className="sideNavButton" href="/media"> Multimedia </a> </li> 
          <li className="newsDescriptionText clearSpaceBottomFooter"> <a className="sideNavButton" href="/news/4"> Geely news </a> </li>
		      </ul>
       </div>
    </div>
  </div>

</div>
</div>
								</div>
							</div>


                           

						
							<div class="side_inner_block customer_care creamBg">


							<div className="desktopSideNav">
									<h2 class="title sideNavButtonContainer">Customer Care</h2>

									<ul class="tools_list">
										<li>
											<a href="https://www.geelyksa.com/road-side-assistance/"> <span class="tool_img"><img width="24" height="24" src="https://www.geelyksa.com/wp-content/uploads/2021/11/roadservice.png.webp" alt=""/></span> <span class="tool_list_text">Road Service</span></a>
										</li>
										<li>
											<a href="/network"> <span class="tool_img"><img width="24" height="24" src="https://www.geelyksa.com/wp-content/uploads/2021/11/network.png.webp" alt=""/></span> <span class="tool_list_text">Network</span></a>
										</li>
										<li>
											<a href="/bookings"> <span class="tool_img"><img width="20" height="21" src="https://www.geelyksa.com/wp-content/uploads/2021/11/servicebooking.png.webp" alt=""/></span> <span class="tool_list_text">Service Booking </span></a>
										</li>
									</ul>
							

								</div>

								<div className="mobileSideNav">
									<h2 class="title sideNavButtonContainer"> USEFUL LINKS </h2>

									<ul class="tools_list">
										<li>
											<a href="#"> <span class="tool_img"><img width="24" height="24" src="https://www.geelyksa.com/wp-content/uploads/2021/11/roadservice.png.webp" alt=""/></span> <span class="tool_list_text">Special Offers</span></a>
										</li>
										<li>
											<a href="#"> <span class="tool_img"><img width="24" height="24" src="https://www.geelyksa.com/wp-content/uploads/2021/11/network.png.webp" alt=""/></span> <span class="tool_list_text">Request a Test drive</span></a>
										</li>
										<li>
											<a href="#"> <span class="tool_img"><img width="20" height="21" src="https://www.geelyksa.com/wp-content/uploads/2021/11/servicebooking.png.webp" alt=""/></span> <span class="tool_list_text"> Request a Quote </span></a>
										</li>
										<li>
											<a href="/contact"> <span class="tool_img"><img width="20" height="21" src="https://www.geelyksa.com/wp-content/uploads/2021/11/servicebooking.png.webp" alt=""/></span> <span class="tool_list_text"> Contact Us </span></a>
										</li>
										<li>
											<a href="/bookings"> <span class="tool_img"><img width="20" height="21" src="https://www.geelyksa.com/wp-content/uploads/2021/11/servicebooking.png.webp" alt=""/></span> <span class="tool_list_text"> Service Booking </span></a>
										</li>
									</ul>
									</div>
								</div>

								<div class="side_bottom_block">
								<ul class="social_links">
									<li><a href="https://facebook.com/Geelymotorsnigeria/" target="_blank"><img width="10" height="19" src="https://www.geelyksa.com/wp-content/uploads/2023/04/side_bar_fb-bl.png.webp"/></a></li>
									<li><a href="#" target="_blank"><img width="20" height="16" src="https://www.geelyksa.com/wp-content/uploads/2023/09/20x16-4thcell.png"/></a></li>
									<li><a href="https://www.instagram.com/geelymotorsnigeria/" target="_blank"><img width="17" height="17" src="https://www.geelyksa.com/wp-content/uploads/2023/04/side_bar_insta.png.webp"/></a></li><a href="https://www.instagram.com/geelyautosaudi/" target="_blank">
									</a><li><a href="#" target="_blank"></a><a href="#" target="_blank"><img width="21" height="16" src="https://www.geelyksa.com/wp-content/uploads/2023/04/side_bar_youtube_b.png.webp"/></a></li>
								</ul>
								<span class="text">© Copyright 2024 Geely All rights reserved.</span>
							</div>

</div>



<div className="container-fluid"> 



<nav className="navbar navbar-expand-lg navbar-light" id="">
<div className="col-4 col-md-2">
<a href="/"> <img src={logo} id="" width="50%" />  </a> 
</div>

   <div className=" col-6 col-md-7 offset-md-1 d-none d-md-block">
   <ul className="navbar-nav white-texttext-center navHeight ">
      <li className="nav-item nav-padding active  d-none d-md-block navLinkArr" onMouseOver={navFunction3} onMouseOut={navFunction4}>
        <a className="nav-link  nav-font" href="#"> About Geely </a>
      </li>
      <li className="nav-item nav-padding  d-none d-md-block navLinkArr">
        <a href="/vehicles" className="nav-link  nav-font" > Vehicles </a>
      </li>
      <li className="nav-item nav-padding  d-none d-md-block navLinkArr" onMouseOver={navFunction5} onMouseOut={navFunction6}>
        <a className="nav-link  nav-font" href="#"> Innovation </a>
      </li>
      <li className="nav-item nav-padding  d-none d-md-block navLinkArr">
        <a className="nav-link  nav-font" href="#"> Offers </a>
      </li>
      <li className="nav-item nav-padding  d-none d-md-block dropdown navLinkArr" onMouseOver={navFunction} onMouseOut={navFunction1}>
        <a className="nav-link  nav-font" href="#"> Service </a>
      </li>
      <li className="nav-item nav-padding  d-none d-md-block navLinkArr" onMouseOver={navFunction7} onMouseOut={navFunction8}>
        <a className="nav-link  nav-font" href="#"> Media </a>
      </li>
    
    
    </ul>

    <div class="first_layer" id="serviceTab" onMouseOver={navFunction} onMouseOut={navFunction1}>
										<ul>
										<li class="first_layer_item">
										<a href="/network" class="first_layer_link">		Service Network 
										</a>
										</li>
										<li class="first_layer_item">
										<a href="/bookings" class="first_layer_link">		Service Request 
										</a>
										</li>
										<li class="first_layer_item">
										<a href="/warranty" class="first_layer_link">		Service Warranty 
										</a>
										</li>
																								<li class="first_layer_item">
													<a href="/maintenance" class="first_layer_link">		Periodic Maintenance 
													</a>
												</li>
																								<li class="first_layer_item">
													<a href="/parts" class="first_layer_link">		Spare Parts 
													</a>
												</li>
																							
												<li class="first_layer_item">
													<a href="/customer-care" class="first_layer_link">		Customer Care 
													</a>
												</li>
														</ul>
									</div>


                  <div class="second_layer" id="aboutTab" onMouseOver={navFunction3} onMouseOut={navFunction4}>
										<ul>
										<li class="second_layer_item">
										<a href="/about" class="second_layer_link">		Our Vision
										</a>
										</li>
										<li class="second_layer_item">
										<a href="/history" class="second_layer_link">		Our History
										</a>
										</li>
										<li class="second_layer_item">
										<a href="/csr" class="second_layer_link">		Corporate Social Responsibility
										</a>
										</li>
										<li class="second_layer_item">
													<a href="/events/3" class="second_layer_link">		Geely Events
													</a>
												</li>
				
																						</ul>
								 	</div>

                  <div class="third_layer" id="innovationTab" onMouseOver={navFunction5} onMouseOut={navFunction6}>
										<ul>
										<li class="third_layer_item">
										<a href="/design" class="third_layer_link">		Design Philosophy
										</a>
										</li>
										<li class="third_layer_item">
										<a href="/research" class="third_layer_link">		Research & Development
										</a>
										</li>

										</ul>
									</div>

                  <div class="fourth_layer" id="mediaTab" onMouseOver={navFunction7} onMouseOut={navFunction8}>
										<ul>
										<li class="fourth_layer_item">
										<a href="/media" class="fourth_layer_link">		Multimedia
										</a>
										</li>
										<li class="fourth_layer_item">
										<a href="/news/4" class="fourth_layer_link">	News center
										</a>
										</li>

										</ul>
									</div>

        

   </div>


    <div className="col-3 col-md-2 text-end">
    <span className="car-spec-img"> <a href="/network">  <img src={maps} />  </a> </span>
    <span className="car-spec-img" id="menu"> <img src={menu}  /> </span>
   
</div>

    </nav>
  </div>

  </div>

  );
}

export default navigation;
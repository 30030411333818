import React, { useState, useEffect, Component } from 'react';
import $ from "jquery";
import warrantyCover from '../images/warranty.jpg';
import tech from '../images/tech.png';
import 'aos/dist/aos.css'
import Nav from './nav.js';
import Footer from './footer.js';
import okText from '../images/Ok-text-logo.png'
import brake from '../images/brake.png';
import roof from '../images/roof.png';
import touch from '../images/touch.png';
import wheel from '../images/wheel.png';
import engine from '../images/engine.png';
import light from '../images/car-light.png';
import starr from '../images/starr.png';
import {
  InMemoryCache,
  ApolloClient,
  gql,
} from '@apollo/client';

const client = new ApolloClient({
  cache:  new InMemoryCache(),
  uri: "https://geely-cms.cs-labs.online/graphql"
});


function Vehicles() {

  const [nameplate, setNamePlate] =  useState("")
  const [nameplateCaption, setNamePlateCaption] =  useState("")
  const [marketingDetail1, setMarketingDetail1] =  useState("")
  const [marketingDetail2, setMarketingDetail2] =  useState("")
  const [marketingDetail3, setMarketingDetail3] =  useState("")
  const [marketingFeatures, setMarketingFeatures] =  useState([])
  const [brochureLink, setBrochureLink] =  useState("")
  const [vehicleId, setVehicleId] =  useState("")
  const [backgroundRight, setBackgroundRight] =  useState("")
  const [backgroundLeft, setBackgroundLeft] =  useState("")

  const [vehicleList, setVehicleList ] = useState([])

  let vehicleArray = []


  function brochure(){
    alert('brochure unavailable at the moment')
  }


  useEffect( () => {
  client
.query({
  query: gql`
   query{
    vehicles {
      edges {
        node {
          id
          geely {
            gelly{
              mediaItemUrl
            }
            carBrochure
            brandName
            carDetails1
            carDetails2
            carDetails3
        
            carFeatures {
              carFeature
              carFeatureImgage {
                mediaItemUrl
              }
            }
            carImageLeft {
              mediaItemUrl
            }
            nameplateCaption
            nameplate {
              mediaItemUrl
            }
            carImageRight {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
  `,
})
.then(async result => {
  console.log(result)
  let automotiveDetailPack 
  

  let x
 
  console.log(result.data.vehicles.edges)

  if (vehicleArray.length != result.data.vehicles.edges.length){
  for(x=0; x < result.data.vehicles.edges.length; x++){ 
       
       automotiveDetailPack = [{carId:result.data.vehicles.edges[x].node.id, gelly:result.data.vehicles.edges[x].node.geely.gelly.mediaItemUrl, brand:result.data.vehicles.edges[x].node.geely.brandName, carBrochure:result.data.vehicles.edges[x].node.geely.carBrochure, nameplate:result.data.vehicles.edges[x].node.geely.nameplate.mediaItemUrl, namePlateCaption:result.data.vehicles.edges[x].node.geely.nameplateCaption, marketingDetail1:result.data.vehicles.edges[x].node.geely.carDetails1, marketingDetail2:result.data.vehicles.edges[x].node.geely.carDetails2, marketingDetail3:result.data.vehicles.edges[x].node.geely.carDetails3, carFeature1:result.data.vehicles.edges[x].node.geely.carFeatures[0].carFeature,carFeatureImage1:result.data.vehicles.edges[x].node.geely.carFeatures[0].carFeatureImgage.mediaItemUrl, carFeature2:result.data.vehicles.edges[x].node.geely.carFeatures[1].carFeature,carFeatureImage2:result.data.vehicles.edges[x].node.geely.carFeatures[1].carFeatureImgage.mediaItemUrl,carFeature3:result.data.vehicles.edges[x].node.geely.carFeatures[2].carFeature,carFeatureImage3:result.data.vehicles.edges[x].node.geely.carFeatures[2].carFeatureImgage.mediaItemUrl, imageRight:result.data.vehicles.edges[x].node.geely.carImageRight.mediaItemUrl, imageLeft:result.data.vehicles.edges[x].node.geely.carImageLeft.mediaItemUrl  }]
 
       vehicleArray.push(automotiveDetailPack)
   
        
  }
  console.log(vehicleArray)
  
  setVehicleList(vehicleArray)
  }


});

}, [])   


  window.scrollTo(0, 0)

let vehicle

  if(vehicleList.length > 0 ){
   //alert("0")

    vehicle = vehicleList.map((vehicle, i) => 
   
        i % 2 === 0 ?    
        <div className="featuredContainer">
         
        <div className="row"> 
  
        <div className="col-12 col-md-6 featuredHeight" style={{backgroundImage:`url(${vehicle[0].imageLeft})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
       
       </div>
       <div className="col-12 col-md-6 featuredHeight" style={{backgroundImage:`linear-gradient(0deg, rgba(232, 233, 232,0.8), rgba(234, 233, 232,0.8)), url(${vehicle[0].imageRight})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
  <div className="col-12 featuredText" data-aos="fade-right" > 
  
  <div className="animateScope">
  <div className="carTitle"> 
      <h2> 
      <img src={vehicle[0].nameplate} width="40%"  />
      <span className="car-sub-title"> 
      {vehicle[0].namePlateCaption}
      </span>
      </h2>
        
  </div>
  
  <div className="car-spec"> 
  <div className="row rowPadding"> 
  <span className="newsDescriptionText marginOff whiteText car-spec-1"> <a href="#" className="bottom_footer_link link car-spec-1"> {vehicle[0].marketingDetail1} </a> <a href="#" className=" bottom_footer_link link car-spec-1"> <bold> {vehicle[0].marketingDetail2} </bold> </a> <a href="#" className="car-spec-1 link"> {vehicle[0].marketingDetail3}   </a>  </span>
  </div>
  
  
  </div>
  
  <div className="car-specs"> 
  <div className="car-spec-detail car-spec-detail-1">
    <span className="car-spec-img">
      <img width="30" decoding="async" src={vehicle[0].carFeatureImage1} className="bright"/>
      </span>
      <span>{vehicle[0].carFeature1}</span>
      </div>
  
      <div className="car-spec-detail car-spec-detail-2">
    <span className="car-spec-img">
      <img width="30" decoding="async" src={vehicle[0].carFeatureImage2} className="bright"/>
      </span>
      <span> {vehicle[0].carFeature2} </span>
      </div>
  
      <div className="car-spec-detail car-spec-detail-3">
    <span className="car-spec-img">
      <img width="30" decoding="async" src={vehicle[0].carFeatureImage3} className="bright"/>
      </span>
      <span> {vehicle[0].carFeature3} </span>
      </div>
  
  </div>
  
  <div className="car-detail-btns">
    < div className="row">
  
    <div className="detail-btn explore-btn col-12 col-md-4 clearBottom">
      <a href={`/${vehicle[0].carId}/explore`}>Explore</a>
      </div>
      <div className="detail-btn finance-btn col-12 col-md-4 clearBottom">
        <a href="/network" target="_blank">Contact</a>
        </div>
        <div className="detail-btn buy-now-btn col-12 col-md-4 clearBotto">
          <a href={vehicle[0].carBrochure} onClick={brochure} target="_blank"> Brochure </a>
          </div>
  
    </div>
   
          </div>
   
  
  </div>
  
  </div>
  </div>
        </div>
        </div> :


<div className="featuredContainer">
         
<div className="row"> 


<div className="col-12 col-md-6 featuredHeight featuredBanner4 d-block d-sm-none " style={{backgroundImage:`url(${vehicle[0].imageLeft})`, backgroundSize:"cover", backgroundPosition:"center"}}> 

</div>
<div className="col-12 col-md-6 featuredHeight featuredBanner3" style={{backgroundImage:`linear-gradient(rgba(53, 53, 53,0.9), rgba(53, 53, 53,0.9)),url(${vehicle[0].imageRight})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
<div className="col-12 featuredText" data-aos="fade-right"  > 

<div className="animateScope">
<div className="carTittle"> 
<h2> 
<img src={vehicle[0].nameplate} width="40%"  />
<span className="car-sub-tittle gFont"> 
{vehicle[0].namePlateCaption}
</span>
</h2>

</div>

<div className="car-specific"> 
<div className="row rowPadding"> 
<span className="newsDescriptionText marginOff whiteText car-spec-1"> <a href="#" className="bottom_footer_link link car-spec-1"> {vehicle[0].marketingDetail1} </a> <a href="#" className=" bottom_footer_link link car-spec-1"> <bold> {vehicle[0].marketingDetail2}</bold> </a> <a href="#" className="car-spec-1 link">{vehicle[0].marketingDetail3}  </a>  </span>
</div>


</div>

<div className="car-specs"> 
<div className="car-spec-detail car-spec-detail-1">
<span className="car-spec-img">
<img width="30" decoding="async" src={vehicle[0].carFeatureImage1} className="bright"/>
</span>
<span className="whiteText">{vehicle[0].carFeature1}</span>
</div>

<div className="car-spec-detail car-spec-detail-2">
<span className="car-spec-img">
<img width="30" decoding="async" src={vehicle[0].carFeatureImage2} className="bright"/>
</span>
<span className="whiteText"> {vehicle[0].carFeature2} </span>
</div>

<div className="car-spec-detail car-spec-detail-3">
<span className="car-spec-img">
<img width="30" decoding="async" src={vehicle[0].carFeatureImage3} className="bright"/>
</span>
<span className="whiteText"> {vehicle[0].carFeature3} </span>
</div>

</div>

<div className="car-detail-btns1">
< div className="row">

<div className="detail-btn explore-btn col-12 col-md-4 clearBottom">
<a href={`${vehicle[0].carId}/explore`}>Explore</a>
</div>
<div className="detail-btn finance-btn col-12 col-md-4 clearBottom">
<a href="/network" target="_blank">Contact</a>
</div>
<div className="detail-btn buy-now-btn col-12 col-md-4 clearBottom">
<a href={`${vehicle[0].carBrochure}`} target="_blank"> Brochure </a>
  </div>

</div>

  </div>


</div>

</div>
</div>
<div className="col-12 col-md-6 featuredHeight featuredBanner4 d-none d-sm-block" style={{backgroundImage:`url(${vehicle[0].imageLeft})`, backgroundSize:"cover", backgroundPosition:"center"}}> 

</div>



</div>
</div>

        

     




  )

    }





if (vehicleList.length > 0){

  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row creamBg"> 
           <div className="col-12 text-center warrantyPad"> 
              <h1> Vehicles </h1>
           </div>
        </div>
       
       
        {vehicle}
      </div>

     

   <Footer />

  </div>

  );

}
}

export default Vehicles;
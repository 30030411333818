import $ from "jquery";
import logo from "../images/geely.png";
import menu from '../images/menu.png';
import maps from '../images/map.png';
import Nav from './nav.js';
import Footer from './footer.js';



function bookings() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row" id="serviceRequestHeight"> 
           <div className="col-12 text-center" id="serviceRequestText"> 
              <h1 className="clearBottomDesktop clearTopMob"> Service Request </h1>
              <h6 className="d-none d-sm-block"> Request in advance saves your valuable time.  </h6>
           </div>
        </div>

        <div className="row"> 
         <div className="col-10 col-md-9 mx-auto" id="formArea"> 
          <div className="row inheritHeight" id="formPlate"> 
            <div className="d-none d-md-block col-md-2 creamBg text-center" id="srBar"> 
            <img decoding="async" width="56" height="618" src="https://www.geelyksa.com/wp-content/uploads/2023/10/geely-SERVICE-REQUEST-ENG-1.jpg" class="attachment-full size-full wp-image-221755" alt=""></img>
            </div>
            <div className="col-12 col-md-10 whiteBg formViewPad"> 
                   
            <form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label formFont">Full name</label>
    <input type="email" class="form-control form-fields" id="exampleInputEmail1" aria-describedby="emailHelp"/>
    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
  </div>
  <div class="mb-3 row">
    <div className="col-12 col-md-6">
    <label for="exampleInputPassword1" class="form-label  formFont">Email Address</label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1"/>
    </div>
    <div className="col-12 col-md-6">
    <label for="exampleInputPassword1" class="form-label  formFont">Phone No</label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1"/>
      </div>

  </div>
  <div class="mb-3 row">
    <div className="col-12 col-md-6">
    <label for="exampleInputPassword1" class="form-label  formFont">Vehicle Type/Model</label>
    <select class="form-select form-fields" aria-label="Default select example">
  <option selected>Choose vehicle type</option>
  <option value="1">Azkara</option>
  <option value="2">Coolray</option>
  <option value="3">Monjaro</option>
  <option value="3">Starray</option>

</select>
    </div>
    <div className="col-12 col-md-6">
    <label for="exampleInputPassword1" class="form-label  formFont">Model Year</label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1"/>
      </div>

  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label formFont">Choose a service center</label>
    <select class="form-select form-fields" aria-label="Default select example">
       <option selected>Coscharis Motors Awoyaya</option>
       <option value="1">Coscharis Motors Victoria Island</option>
          <option value="2"> Coscharis Motors Abuja</option>
           <option value="3">Coscharis Motors Kano</option>   
</select>

  </div>

  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
    <label class="form-check-label formFont" for="exampleCheck1">I have read and accept* <span> (Terms and Conditions) </span></label>
  </div>
  <button type="submit" class="blackBtn">Submit Enquiry</button>
</form>

            
            </div>
          </div>
         </div>
        </div>

      </div>

   <Footer />

  </div>

  );
}

export default bookings;
import {useState, useEffect} from 'react';
import { Link, Outlet, useParams } from "react-router-dom";
import $ from "jquery";
import warrantyCover from '../images/warranty.jpg';
import tech from '../images/tech.png';
import Nav from './nav.js';
import newsBanner from '../images/Geely-News-Center-Banners-01-scaled.jpg';
import Footer from './footer.js';
import newsCover from '../images/news.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftRotate, faChevronRight, faCalendar} from '@fortawesome/free-solid-svg-icons'
import { faArrowRightRotate} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import {
   InMemoryCache,
   ApolloClient,
   gql,
 } from '@apollo/client';
 
 const client = new ApolloClient({
   cache:  new InMemoryCache(),
   uri: "https://geely-cms.cs-labs.online/graphql"
 });

function NewsPage() {

   
   const[newsList , setNewsList] = useState([])
   const[newsHeading, setNewsHeading] = useState("")
   const[newsBody, setNewsBody] = useState("")
   const[Banner, setBanner] = useState("")
   const[date, setDate] = useState("")

   
let newsArray =[]
const params = useParams()

useEffect( () => {
   client
   .query({
     query: gql`
       query($id:ID!){
            newBy(id:$id) {
              date
              news {
                banner {
                  mediaItemUrl
                }
                body
                subject
              }
            }
       
        }
   
      `,
      variables: {id:params.id}
   })
   .then(async result => { 
       console.log(result)

       setNewsHeading(result.data.newBy.news.subject)
       setNewsBody(result.data.newBy.news.body)
       setBanner(result.data.newBy.news.banner.mediaItemUrl)
       setDate(result.data.newBy.date)

   })



   client
   .query({
     query: gql`
     query($id: Int!){
      news(where:{categoryId:$id}) {
         edges {
           node {
             categories {
               edges {
                 node {
                   categoryId
                   news {
                     edges {
                       node {
                        id      
                         date
                         news {
                           banner {
                             mediaItemUrl
                           }
                           body
                           subject
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       }
   }
   `,
   variables: {id:4}
   })
   .then(async result => { 
    console.log(result)

     let newsDetailPack 
     let x = 0
     let y = 0 

   if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){
   
         for(x; x < result.data.news.edges[0].node.categories.edges[0].node.news.edges.length; x++){ 
            console.log(x)
       // alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
          if(result.data.news.edges[0].node.categories.edges[0].node.categoryId == "4" && result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.id != params.id){ 
             
          
            newsDetailPack = [{id:result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.id,subject:result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.news.subject, banner:result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.news.banner.mediaItemUrl, date:moment(result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.date).format("MMM Do YYYY"), body:result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.news.body }]
    

            
            if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length -1){    
                        newsArray.push(newsDetailPack)
                
            }

   
        
             
       }
       console.log(newsArray)
       
       setNewsList(newsArray)
    }
   

    }
    

   })


}, [])   

   window.scrollTo(0, 0)



    let newsDetails
    let newsOutline
    if(newsList.length > 0 ){
    newsDetails = newsList.map((news, i) =>    
      
  
        <div className="row clearSpace"> 
        <div className="col-12 col-md-6"> 
        
        <img src={news[0].banner} className='radi' width="120" height="120" />
        
        </div>
        <div className="col-12 col-md-6 paddingOff"> 
            <p className='recentFont marginOff recentPadding' > {news[0].subject} </p>
            <a className="link elementor-post__read-more" href={`/news/${news[0].subject}/${news[0].id}`} aria-label={`Read more about ${news[0].subject}`} tabindex="-1">
                    Read More		
            </a>
            </div>
        
            <div class="elementor-divider clearSpace">
                    <span class="elementor-divider-separator">
                                </span>
        </div>
         </div>


    )


    newsOutline = newsList.map((news, i) =>    
      
      <div className="item">  
      <a href={`/news/${news[0].subject}/${news[0].id}`} className="link" >
      <h5> {news[0].subject}</h5>
      </a>

      <h6> {news[0].date} </h6>
        </div>


    )
   
   
   
   
   
   
   
   }
  


  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row warrantyPad creamBg"> 
           <div className="col-12 text-center"> 
           
           </div>
        </div>

        <div className="row"> 
           <img src={Banner} width="100%" height="400px" className="paddingOff" />
      
        </div>

        <div className="container clearSpaceCarousel"> 
       <div className="row">
       <div className="col-12 col-md-7 elementor-element-populated recentPadding1"> 

<div>
    <div className="recentPadding1">
    <b>  <h2 className="geelyHeading "> {newsHeading} </h2></b>
<h6 className="eventDate"> <FontAwesomeIcon icon={faCalendar} />  {date} </h6>
    </div>


</div>
<div className="elementor-widget-container recentPadding1"> 

   {newsBody}

</div>



</div>


<div className="col-12 offset-md-1 col-md-3 elementor-element-populated recentPadding1"> 

<div>
<b>  <h2 className="geelyHeading"> Recent News </h2> </b>

<div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
</div>

{newsDetails}

</div>

</div>


       </div>
     

   
        
        
        
        
        </div>








      </div>

   <Footer />

  </div>

  );
}

export default NewsPage;
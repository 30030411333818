import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Landing from './components/landing';
import Booking from './components/bookings';
import Warranty from './components/warranty';
import Care from './components/care.js';
import Maintain from './components/maintainance.js';
import Parts from './components/parts.js';
import Vehicles from './components/vehicles.js';
import Network from './components/network.js';
import News from './components/news.js';
import AllNews from './components/latestNews';
import CSR from './components/csr.js';
import About from './components/about.js';
import Event from './components/events.js';
import History from './components/history.js';
import Explore from './components/explore.js';
import Rd from './components/rd.js';
import Design from './components/design.js';
import NewsSingle from './components/newspage.js';
import EventSingle from './components/eventpage.js';
import Media from './components/media.js';
import Contact from './components/contact.js';
import Testdrive from './components/testdrive.js'; 
import Quote from './components/quote.js';



function App() {
  return (
    <Router> 
    <Routes> 
   
       <Route exact path="/" element={<Landing/>} />
       <Route exact path="/bookings" element={<Booking/>} />
       <Route exact path="/warranty" element={<Warranty/>} />
       <Route exact path="/customer-care" element={<Care/>} />
       <Route exact path="/maintenance" element={<Maintain/>} />
       <Route exact path="/parts" element={<Parts/>} />
       <Route exact path="/vehicles" element={<Vehicles/>} />
       <Route exact path="/network" element={<Network/>} />
       <Route exact path=":id/explore" element={<Explore/>} />
       <Route exact path="/news/:categoryId" element={<News/>}>
  
        
     

       <Route path="all-News" element={<AllNews/>} />
         
                 
      </Route>
      <Route exact path="/testdrive/" element={<Testdrive/>} />
      <Route exact path="/testdrive/:vehicle " element={<Testdrive/>}/>
      <Route exact path="/quote" element={<Quote/>} />
      <Route exact path="/news/:subject/:id" element={<NewsSingle/>} />
      <Route exact path="/events/:subject/:id" element={<EventSingle/>} />
      <Route exact path="/csr" element={<CSR/>} />
      <Route exact path="/about" element={<About/>} />
      <Route exact path="/events/:categoryId" element={<Event/>} />
      <Route exact path="/history" element={<History/>}/>
      <Route exact path="/research" element={<Rd/>}/>
      <Route exact path="/design" element={<Design/>} />
      <Route exact path="/media" element={<Media/>} />
      <Route exact path="/contact" element={<Contact/>} />




</Routes>
</Router> 
  );
}

export default App;

import {useState, useEffect} from 'react';
import { Link, Outlet, useParams } from "react-router-dom";
import $ from "jquery";
import warrantyCover from '../images/warranty.jpg';
import tech from '../images/tech.png';
import Nav from './nav.js';
import newsBanner from '../images/Geely-News-Center-Banners-01-scaled.jpg';
import Footer from './footer.js';
import newsCover from '../images/news.jpg';
import moment from 'moment';
import media from '../images/media.jpg';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import {
   InMemoryCache,
   ApolloClient,
   gql,
 } from '@apollo/client';
 
 const client = new ApolloClient({
   cache:  new InMemoryCache(),
   uri: "https://geely-cms.cs-labs.online/graphql"
 });

function Media() {

   
   const[galleryList , setGalleryList] = useState([])
 
   let galleryArray =[]
const params = useParams()

useEffect( () => {
   client
   .query({
     query: gql`
     query{
        pages {
            edges {
              node {
                gallery {
                  gallery {
                    galimage {
                      mediaItemUrl
                    }
                  }
                }
              }
            }
          }
       }
   `,
   })
   .then(async result => { 
    console.log(result)


    let galleryDetailPack 
    let x = 0

  if (galleryArray.length != result.data.pages.edges[3].node.gallery.gallery.length){
  
        for(x; x < result.data.pages.edges[3].node.gallery.gallery.length; x++){ 
           console.log(x)
     //  alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
         
           galleryDetailPack = [{image:result.data.pages.edges[3].node.gallery.gallery[x].galimage.mediaItemUrl}]
           galleryArray.push(galleryDetailPack)
       
      console.log(galleryArray) 
      setGalleryList(galleryArray)

   }
  

   }

   })

}, [])   

   window.scrollTo(0, 0)

 


    let galleryDetails
    
    if(galleryList.length > 0 ){
    galleryDetails = galleryList.map((gallery, i) =>    
      
     <div className="warrantyWidth" style={{backgroundImage:`url(${gallery[0].image})`, backgroundSize:"cover", backgroundPosition:"center"}} >
        <a class="" href="" data-fancybox="gallery" data-src={gallery[0].image}>
        <div className="warrantyImage">
        <figure class="elementor-image-box-img">
           <img loading="lazy" decoding="async" width="100%" height="" src={gallery[0].image} class="attachment-full size-full wp-image-159063" alt=""/>
           </figure>
        
        </div>
        </a>
          </div>

    )

   
   
   
   
   
   
   
   }
  


  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row warrantyPad creamBg"> 
           <div className="col-12 text-center"> 
              <h1> IMAGES AND VIDEOS </h1>
           </div>
        </div>

        <div className="row"> 
           <img src={media} width="100%" className="paddingOff" />
           <div className="col-12 creamBg newsPadding"> 
         

         <div className="owl-carousel owl-theme" id="third">
         
         
     
       
             </div>
         </div>


     
        </div>

        <div className="container clearSpaceCarousel clearSpaceBottom"> 
    


      <div className="flexDisplay warrantyContainer">


{galleryDetails}






</div>

        </div>






      </div>

   <Footer />

  </div>

  );
}

export default Media;
import {useState, useEffect} from 'react';
import { Link, Outlet, useParams } from "react-router-dom";
import $ from "jquery";
import warrantyCover from '../images/warranty.jpg';
import tech from '../images/tech.png';
import Nav from './nav.js';
import newsBanner from '../images/Geely-News-Center-Banners-01-scaled.jpg';
import Footer from './footer.js';
import newsCover from '../images/news.jpg';
import moment from 'moment';
import {
   InMemoryCache,
   ApolloClient,
   gql,
 } from '@apollo/client';
 
 const client = new ApolloClient({
   cache:  new InMemoryCache(),
   uri: "https://geely-cms.cs-labs.online/graphql"
 });

function News() {

   
   const[newsList , setNewsList] = useState([])
   let newsArray =[]
const params = useParams()

useEffect( () => {
   client
   .query({
     query: gql`
     query($id: Int!){
      news(where:{categoryId:$id}) {
         edges {
           node {
             categories {
               edges {
                 node {
                   categoryId
                   news {
                     edges {
                       node {
                        id      
                         date
                         news {
                           banner {
                             mediaItemUrl
                           }
                           body
                           subject
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       }
   }
   `,
   variables: {id:4}
   })
   .then(async result => { 
    console.log(result)

     let newsDetailPack 
     let x = 0

   if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){
   
         for(x; x < result.data.news.edges[0].node.categories.edges[0].node.news.edges.length; x++){ 
            console.log(x)
     //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
          if(result.data.news.edges[0].node.categories.edges[0].node.categoryId == "4"){ 
             
          
            newsDetailPack = [{id:result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.id,subject:result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.news.subject, banner:result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.news.banner.mediaItemUrl, date:moment(result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.date).format("MMM Do YYYY"), body:result.data.news.edges[0].node.categories.edges[0].node.news.edges[x].node.news.body }]
            newsArray.push(newsDetailPack)
        
             
       }
       console.log(newsArray)
       
       setNewsList(newsArray)
    }
   

    }
    

   })

}, [])   

   window.scrollTo(0, 0)

    function newsManager(){
        document.getElementById("newsManager").style.display ="none"

    }
    function newsManagerAlt(){
        document.getElementById("newsManager").style.display ="flex"

    }


    let newsDetails
    let newsOutline
    if(newsList.length > 0 ){
    newsDetails = newsList.map((news, i) =>    
      
      <div className="creamBg newsWidth"> 
      <div className="warrantyImage">
         
      <figure class="elementor-image-box-img">
         <img loading="lazy" decoding="async" width="406" height="208" src={news[0].banner} class="attachment-full size-full wp-image-159063" alt=""/>
         </figure>
      </div>
      <div className="nav-padding"> 
      <a href={`/news/${news[0].subject}/${news[0].id}`} className="link" >
      <div class="elementor-image-box-content text-start">
         <p class="elementor-image-box-description">{news[0].subject} </p>
         </div>
         </a>
         <div class="elementor-image-box-content text-start">
         <p class="elementor-image-box-description">{news[0].date} </p>
         </div>
        
      </div>
      </div>


    )


    newsOutline = newsList.map((news, i) =>    
      
      <div className="item">  
      <a href={`/news/${news[0].subject}/${news[0].id}`} className="link" >
      <h5> {news[0].subject}</h5>
      </a>

      <h6> {news[0].date} </h6>
        </div>


    )
   
   
   
   
   
   
   
   }
  


  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row warrantyPad creamBg"> 
           <div className="col-12 text-center"> 
              <h1> News Center</h1>
           </div>
        </div>

        <div className="row"> 
           <img src={newsBanner} width="100%" className="paddingOff" />
           <div className="col-12 creamBg newsPadding"> 
         

         <div className="owl-carousel owl-theme" id="third">
         
         
     
            {newsOutline}

             </div>
         </div>


     
        </div>

        <div className="container clearSpaceCarousel clearSpaceBottom"> 
     
        <div class="column-news">

           <div class="news-tittle">
               <h2 class="title">Geely News</h2>
          </div>
       <div class="social_tabs"><Link to="/news" class="tabs_btn active_tabs" onClick={newsManagerAlt}>Latest News</Link><span class="divider"></span><Link class="tabs_btn" to="all-news" onClick={newsManager}>All News</Link></div>
            <div class="list-news">
              
      <div class="column-view">
               <span href="#" class="tabs_icon" data-tab="1"><img width="19" height="19" decoding="async" class="g_icon_src" src="https://www.geelyksa.com/wp-content/uploads/2023/04/column-view-active.png.webp"/></span>
       </div>
      </div>
      </div>


      <div className="flexDisplay warrantyContainer" id="newsManager">
  

{newsDetails}



  </div>


        <Outlet />
     
        </div>






      </div>

   <Footer />

  </div>

  );
}

export default News;
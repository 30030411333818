import $ from "jquery";
import {useState, useEffect} from 'react';
import AOS from 'aos'
import 'aos/dist/aos.css'
import Nav from './nav.js';
import Footer from './footer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftRotate, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { faArrowRightRotate} from '@fortawesome/free-solid-svg-icons'
import {useParams, Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import topBanner from '../images/geely.jpeg';
import slide1 from '../images/star.jpeg';
import slide2 from '../images/coolray.jpeg';
import slide3 from '../images/Tugella.jpeg';
import slide4 from '../images/azkara.jpeg';
import slide5 from '../images/vango.jpeg';
import text from '../images/text.png';
import oneDesk from '../images/Onee.jpg';
import twoDesk from '../images/two.jpg';
import threeDesk from '../images/three.webp';
import fourDesk from '../images/four.webp';
import oneMob from '../images/oneMob.jpeg';
import twoMob from '../images/twoMob.jpeg';
import threeMob from '../images/threeMob.webp';
import fourMob from '../images/fourMob.webp';
import azk from '../images/azkara.png';
import two from '../images/2.png';
import three from '../images/3.png';
import four from '../images/4.png';
import five from '../images/5.png';
import six from '../images/6.png';
import quote from '../images/quote.png';
import offer from '../images/offers.png';
import finance from '../images/finance.png';
import location from '../images/geely-dealers.png'; 
import testDrive from '../images/test-drive.png';
import oz from '../images/oz.png'; 
import star from '../images/star.png';
import mon from '../images/Mon.png';
import emg from '../images/Emg.png';
import okText from '../images/Ok-text-logo.png'
import brake from '../images/brake.png';
import roof from '../images/roof.png';
import touch from '../images/touch.png';
import wheel from '../images/wheel.png';
import engine from '../images/engine.png';
import light from '../images/car-light.png';
import starr from '../images/starr.png';
import f1 from '../images/f3.jpg';
import f2 from '../images/f2.jpg';
import f3 from '../images/f1.jpg';
import Carousel from 'react-bootstrap/Carousel';
import CarouselMob from 'react-bootstrap/Carousel';
import {
  InMemoryCache,
  ApolloClient,
  gql,
} from '@apollo/client';

const client = new ApolloClient({
  cache:  new InMemoryCache(),
  uri: "https://geely-cms.cs-labs.online/graphql"
});




function Landing() {

  const params = useParams()
  window.scrollTo(0, 0)
  let arrayIndex 


  const [vehicleImage1, setVehicleImage1] = useState(azk);
  const [vehicleImage2, setVehicleImage2] = useState(two);
  const [vehicleImage3, setVehicleImage3] = useState(three);
  const [vehicleImage4, setVehicleImage4] = useState(four);
  const [vehicleImage5, setVehicleImage5] = useState(five);
  const [vehicleImage6, setVehicleImage6] = useState(six);
  const [vehicleImages, setVehicleImages] = useState([vehicleImage1, vehicleImage2 , vehicleImage3, vehicleImage4, vehicleImage5, vehicleImage6]);
  const [vehicleView, setVehicleView] = useState(vehicleImages.at(arrayIndex))

  const [nameplate, setNamePlate] =  useState("")
  const [nameplateCaption, setNamePlateCaption] =  useState("")
  const [marketingDetail1, setMarketingDetail1] =  useState("")
  const [marketingDetail2, setMarketingDetail2] =  useState("")
  const [marketingDetail3, setMarketingDetail3] =  useState("")
  const [marketingFeatures, setMarketingFeatures] =  useState([])
  const [brochureLink, setBrochureLink] =  useState("")
  const [vehicleId, setVehicleId] =  useState("")
  const [backgroundRight, setBackgroundRight] =  useState("")
  const [backgroundLeft, setBackgroundLeft] =  useState("")

  const [vehicleList, setVehicleList ] = useState([])
  const [vehicleList1, setVehicleList1 ] = useState([])
  const [bannerList, setBannerList] = useState([])
  

  useEffect(() => {
    let vehicleArray = []
    let vehicleArray1 = []
    let bannerArray = []

    AOS.init({ duration: 1000 })


      client
    .query({
      query: gql`
       query{
        vehicles {
          edges {
            node {
              id
              geely {
                gelly{
                  mediaItemUrl
                }
                carBrochure
                brandName
                carDetails1
                carDetails2
                carDetails3
            
                carFeatures {
                  carFeature
                  carFeatureImgage {
                    mediaItemUrl
                  }
                }
                carImageLeft {
                  mediaItemUrl
                }
                nameplateCaption
                nameplate {
                  mediaItemUrl
                }
                carImageRight {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
      `,
    })
    .then(async result => {
      console.log(result)
      let automotiveDetailPack 
      
    
      let x
     
      //alert(result.data.vehicles.edges.length)
    
      if (vehicleArray.length != result.data.vehicles.edges.length){
      for(x=0; x < result.data.vehicles.edges.length; x++){ 
           
           automotiveDetailPack = [{carId:result.data.vehicles.edges[x].node.id, gelly:result.data.vehicles.edges[x].node.geely.gelly.mediaItemUrl, brand:result.data.vehicles.edges[x].node.geely.brandName, carBrochure:result.data.vehicles.edges[x].node.geely.carBrochure, nameplate:result.data.vehicles.edges[x].node.geely.nameplate.mediaItemUrl, namePlateCaption:result.data.vehicles.edges[x].node.geely.nameplateCaption, marketingDetail1:result.data.vehicles.edges[x].node.geely.carDetails1, marketingDetail2:result.data.vehicles.edges[x].node.geely.carDetails2, marketingDetail3:result.data.vehicles.edges[x].node.geely.carDetails3, carFeature1:result.data.vehicles.edges[x].node.geely.carFeatures[0].carFeature,carFeatureImage1:result.data.vehicles.edges[x].node.geely.carFeatures[0].carFeatureImgage.mediaItemUrl, carFeature2:result.data.vehicles.edges[x].node.geely.carFeatures[1].carFeature,carFeatureImage2:result.data.vehicles.edges[x].node.geely.carFeatures[1].carFeatureImgage.mediaItemUrl,carFeature3:result.data.vehicles.edges[x].node.geely.carFeatures[2].carFeature,carFeatureImage3:result.data.vehicles.edges[x].node.geely.carFeatures[2].carFeatureImgage.mediaItemUrl, imageRight:result.data.vehicles.edges[x].node.geely.carImageRight.mediaItemUrl, imageLeft:result.data.vehicles.edges[x].node.geely.carImageLeft.mediaItemUrl }]
     
           vehicleArray.push(automotiveDetailPack)
           if(vehicleArray1.length < 2){
            vehicleArray1.push(automotiveDetailPack)
           }
          
       
            
      }
      console.log(vehicleArray)
      
      setVehicleList(vehicleArray)
      setVehicleList1(vehicleArray1)
      }
    
    
    });


    client
    .query({
      query: gql`
      query{
    pages {
      edges {
        node {
          homepage {
            coverBanners {
              carImage {
                mediaItemUrl
              }
              caption{
                mediaItemUrl
              }
              subCaption
              cta
              mobileimage {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
  `,
})
.then(async result => {
  console.log(result)
  let bannerDetailPack
  let x 

  if (bannerArray.length != result.data.pages.edges[2].node.homepage.coverBanners.length){
    for(x=0; x < result.data.pages.edges[2].node.homepage.coverBanners.length - 1; x++){ 
         
         bannerDetailPack = [{desktopImage:result.data.pages.edges[2].node.homepage.coverBanners[x].carImage.mediaItemUrl , mobileImage:result.data.pages.edges[2].node.homepage.coverBanners[x].mobileimage.mediaItemUrl, caption:result.data.pages.edges[2].node.homepage.coverBanners[x].caption.mediaItemUrl, subCaption:result.data.pages.edges[2].node.homepage.coverBanners[x].subCaption, cta:result.data.pages.edges[2].node.homepage.coverBanners[x].cta }]
         bannerArray.push(bannerDetailPack)
     
    }

    console.log(bannerArray)
    
    setBannerList(bannerArray)

    }
    
  

})
      
  }, [])



  let bannerDetails
  let bannerDetailsMobile
  if(bannerList.length > 0 ){


bannerDetails = bannerList.map((banner, i) => 

  <Carousel.Item>    
     <img src={banner[0].desktopImage} width="100%" />  
  <Carousel.Caption className="ksaStyleDesk">
  <img src={banner[0].caption} width="65%" className="bottomMargin" />
    <p>{banner[0].subCaption}</p>
    <div className="car-detail-btns1">
< div className="row">
<div className="detail-btn banner-btn col-12 col-md-4 clearBottom">
<Link to={banner[0].cta} target="_blank">Learn More</Link>
</div>
</div>
</div>

  </Carousel.Caption>
</Carousel.Item>

  )


bannerDetailsMobile = bannerList.map((banner, i) => 
  
   <Carousel.Item>    
     <img src={banner[0].mobileImage} width="100%"  />  
  <Carousel.Caption className="ksaStyle">
  <img src={banner[0].caption} width="65%" className="bottomMargin" />
    <p className="capMobile">{banner[0].subCaption}</p>
    <div className="car-detail-btns1">
< div className="row">
<div className="detail-btn banner-btn col-12 col-md-4 clearBottom">
<Link to={banner[0].cta} target="_blank">Learn More</Link>
</div>
</div>
</div>
  </Carousel.Caption>
</Carousel.Item>
  
     
  )}
    

  let vehicleGelly
  if(vehicleList.length > 0 ){
  vehicleGelly = vehicleList.map((vehicle, i) => 
   <Link to={`/${vehicle[0].carId}/explore`} className="link">
    
   <div className="item">  
     <figure className="text-center"> 
     <img src={vehicle[0].gelly}  width="100%"  /> 
     </figure>
     <figcaption className="text-center"> 
      {vehicle[0].brand}
     </figcaption> 
   </div>

   </Link>
     
  )}


  let vehicle

  if(vehicleList1.length > 0 ){
   //alert("0")

    vehicle = vehicleList1.map((vehicle, i) => 
   
        i % 2 === 0 ?    
        <div className="featuredContainer">
         
        <div className="row fullWidthExt"> 
  
        <div className="col-12 col-md-6 featuredHeight featuredBanner1" style={{backgroundImage:`url(${vehicle[0].imageLeft})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
       
       </div>
       <div className="col-12 col-md-6 featuredHeight" style={{backgroundImage:`linear-gradient(0deg, rgba(232, 233, 232,0.8), rgba(234, 233, 232,0.8)), url(${vehicle[0].imageRight})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
  <div className="col-12 featuredText" data-aos="fade-right" > 
  
  <div className="animateScope">
  <div className="carTitle"> 
      <h2> 
      <img src={vehicle[0].nameplate} width="40%"  />
      <span className="car-sub-title"> 
      {vehicle[0].namePlateCaption}
      </span>
      </h2>
        
  </div>
  
  <div className="car-spec"> 
  <div className="row rowPadding"> 
  <span className="newsDescriptionText marginOff whiteText car-spec-1"> <a href="#" className="bottom_footer_link link car-spec-1"> {vehicle[0].marketingDetail1} </a> <a href="#" className=" bottom_footer_link link car-spec-1"> <bold> {vehicle[0].marketingDetail2} </bold> </a> <a href="#" className="car-spec-1 link"> {vehicle[0].marketingDetail3}   </a>  </span>
  </div>
  
  
  </div>
  
  <div className="car-specs"> 
  <div className="car-spec-detail car-spec-detail-1">
    <span className="car-spec-img">
      <img width="30" decoding="async" src={vehicle[0].carFeatureImage1} className="bright"/>
      </span>
      <span>{vehicle[0].carFeature1}</span>
      </div>
  
      <div className="car-spec-detail car-spec-detail-2">
    <span className="car-spec-img">
      <img width="30" decoding="async" src={vehicle[0].carFeatureImage2} className="bright"/>
      </span>
      <span> {vehicle[0].carFeature2} </span>
      </div>
  
      <div className="car-spec-detail car-spec-detail-3">
    <span className="car-spec-img">
      <img width="30" decoding="async" src={vehicle[0].carFeatureImage3} className="bright"/>
      </span>
      <span> {vehicle[0].carFeature3} </span>
      </div>
  
  </div>
  
  <div className="car-detail-btns">
    < div className="row">
  
    <div className="detail-btn explore-btn col-12 col-md-4 clearBottom">
      <a href={`/${vehicle[0].carId}/explore`}>Explore</a>
      </div>
      <div className="detail-btn explore-btn col-12 col-md-4 clearBottom">
        <a href="/network" target="_blank">Contact</a>
        </div>
        <div className="detail-btn buy-now-btn col-12 col-md-4 clearBotto">
          <a href={vehicle[0].carBrochure} target="_blank"> Brochure </a>
          </div>
  
    </div>
   
          </div>
   
  
  </div>
  
  </div>
  </div>
        </div>
        </div> :


<div className="featuredContainer">
         
<div className="row fullWidthExt"> 


<div className="col-12 col-md-6 featuredHeight featuredBanner4 d-block d-sm-none " style={{backgroundImage:`url(${vehicle[0].imageLeft})`, backgroundSize:"cover", backgroundPosition:"center"}}> 

</div>
<div className="col-12 col-md-6 featuredHeight featuredBanner3" style={{backgroundImage:`linear-gradient(rgba(53, 53, 53,0.9), rgba(53, 53, 53,0.9)),url(${vehicle[0].imageRight})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
<div className="col-12 featuredText" data-aos="fade-right"  > 

<div className="animateScope">
<div className="carTittle"> 
<h2> 
<img src={vehicle[0].nameplate} width="40%"  />
<span className="car-sub-tittle gFont"> 
{vehicle[0].namePlateCaption}
</span>
</h2>

</div>

<div className="car-specific"> 
<div className="row rowPadding"> 
<span className="newsDescriptionText marginOff whiteText car-spec-1"> <a href="#" className="bottom_footer_link link car-spec-1 whiteText"> {vehicle[0].marketingDetail1} </a> <a href="#" className=" bottom_footer_link link car-spec-1 whiteText"> <bold> {vehicle[0].marketingDetail2}</bold> </a> <a href="#" className="car-spec-1 link whiteText">{vehicle[0].marketingDetail3}  </a>  </span>
</div>


</div>

<div className="car-specs"> 
<div className="car-spec-detail car-spec-detail-1">
<span className="car-spec-img">
<img width="30" decoding="async" src={vehicle[0].carFeatureImage1} className=""/>
</span>
<span className="whiteText">{vehicle[0].carFeature1}</span>
</div>

<div className="car-spec-detail car-spec-detail-2">
<span className="car-spec-img">
<img width="30" decoding="async" src={vehicle[0].carFeatureImage2} className=""/>
</span>
<span className="whiteText"> {vehicle[0].carFeature2} </span>
</div>

<div className="car-spec-detail car-spec-detail-3">
<span className="car-spec-img">
<img width="30" decoding="async" src={vehicle[0].carFeatureImage3} className=""/>
</span>
<span className="whiteText"> {vehicle[0].carFeature3} </span>
</div>

</div>

<div className="car-detail-btns1">
< div className="row">

<div className="detail-btn explore-btn col-12 col-md-4 clearBottom">
<a href={`${vehicle[0].carId}/explore`}>Explore</a>
</div>
<div className="detail-btn explore-btn col-12 col-md-4 clearBottom">
<a href="/network" target="_blank">Contact</a>
</div>
<div className="detail-btn buy-now-btn col-12 col-md-4 clearBottom">
<a href={`${vehicle[0].carBrochure}`} target="_blank"> Brochure </a>
  </div>

</div>

  </div>


</div>

</div>
</div>
<div className="col-12 col-md-6 featuredHeight featuredBanner4 d-none d-sm-block" style={{backgroundImage:`url(${vehicle[0].imageLeft})`, backgroundSize:"cover", backgroundPosition:"center"}}> 

</div>



</div>
</div>

        

     




  )

    }


 
  $(document).ready(function() {
    var $item = 0,
      $itemNo = $(".hero figure").length;
    function transitionSlide() {
      $item++;
      if ($item > $itemNo - 1) {
        $item = 0;
      }
      $(".hero figure").removeClass("on");
      $(".hero figure")
        .eq($item)
        .addClass("on");
    }
    var $autoTransition = setInterval(transitionSlide, 3500);
  
    $(".hero figure").click(function() {
      clearInterval($autoTransition);
      $item = $(this).index();
      $(".hero figure").removeClass("on");
      $(".hero figure")
        .eq($item)
        .addClass("on");
      $autoTransition = setInterval(transitionSlide, 3500);
    });
  });
  

  

  function vehicleRotationRight() { 
    //alert(arrayIndex)
        
      let indexString = $('.vehicleReview').attr('id')
   
      let indexInt = parseInt(indexString)
      // alert(typeof(indexInt))
      // alert(indexInt)

         if(indexInt != 0){ 
           //  alert('ran')
              indexInt--
        
         }else{
           indexInt += 5
         }
   
       //  alert(indexInt)
         setVehicleView(vehicleImages.at(indexInt))
         $('.vehicleReview').attr('id', indexInt)

 
      
  }



  function vehicleRotationLeft() { 
    //alert(arrayIndex)
        
      let indexString = $('.vehicleReview').attr('id')
   
      let indexInt = parseInt(indexString)
      // alert(typeof(indexInt))
      // alert(indexInt)

         if(indexInt != 5){ 
          //   alert('ran')
              indexInt++
        
         }else{
           indexInt -= 5
         }
   
       //  alert(indexInt)
         setVehicleView(vehicleImages.at(indexInt))
         $('.vehicleReview').attr('id', indexInt)

 
      
  }


  return (
  
<div className="container-fluid paddingOff"> 
   <Nav/>
   
   <div className=""> 

   <Carousel  className="d-none d-sm-block">
     {bannerDetails}
    </Carousel>



    <CarouselMob controls={false} className="d-block d-sm-none">
     {bannerDetailsMobile}
    </CarouselMob>


<div className="main-nav"> 
<div className="owl-nav"> 
</div>
     <div className="owl-dots paddingRLOff"> 
     </div>


</div>



     
   </div>

   <div className="tools"> 
          <div className='col-12 col-md-9 mx-auto'>
            <div className="toolHeadingSection text-center marginBottomStandard"> 
                <h3 className="toolHeading gFont"> Shopping Tools </h3>
            </div> 
            <div className="col-12 col-md-10 mx-auto">
            <div className="toolsOverview container-fluid row marginTopStandard fullWidth"> 

            <div className="col-6 col-md-2 offset-md-1 text-center borderBottom toolsPadding"> 
               <figure> 
               <a href="/testdrive" className="link">
                <img src={testDrive} /> 
              
               <figcaption> 
                Test Drive
               </figcaption>
               </a>
               </figure>
            </div>


            <div className="col-6 col-md-2 text-center borderBottom toolsPadding"> 
               <figure> 
               <a href="/contact" className="link">
                <img src={finance} /> 
              
               <figcaption> 
                Finance
               </figcaption>
               </a>
               </figure>
            </div>

            <div className="col-6 col-md-2 text-center borderRight toolsPadding"> 
               <figure> 
               <a href="/quote" className="link">
                <img src={quote} /> 
            
               <figcaption> 
                Request a Quote
               </figcaption>
               </a>
               </figure>
            </div>


            <div className="col-6 col-md-2 text-center toolsPadding"> 
            
               <figure> 
               <a href="/network" className="link">
                <img src={location} /> 
              
               <figcaption> 
              
               Dealerships
                
               </figcaption>
               </a>
               </figure>

      
             
            </div>

            <div className="col-12 col-md-2 text-center d-none d-md-block"> 
               <figure> 
               <a href="/network" className="link">
                <img src={offer} /> 
               <figcaption> 
               Offers
               </figcaption>
               </a>
               </figure>
            </div>

       

            
            </div>
            </div>
          
          </div>
   </div>


   <div className="range creamBg"> 
          <div className='col-12 col-md-9 mx-auto'>
            <div className="toolHeadingSection text-center marginBottomStandard"> 
                <h3 className="toolHeading gFont"> MODELS </h3>
            </div> 
            <div className="col-12 col-md-10 mx-auto">
            <div className="toolsOverview row container-fluid marginTopStandard fullWidth"> 

           
         
         <div className="owl-carousel owl-theme" id="second" data-aos="fade-up">
         
           {vehicleGelly}

             </div>

       

         

       

            
            </div>
            </div>
          
          </div>
   </div>

  

{vehicle}

         <div className="clearPadRight creamBg">
         <div id="hero" className="" data-aos="fade-up">
        

          
  <div className="hero">
    <figure className="on">
      <img src="http://geely-cms.cs-labs.online/wp-content/uploads/2024/07/Homepage-Gallery.jpg" />
      <div className="col-12 dark-bg text-center ">
        <h2 className="neo">STARRAY</h2>
      </div>
    </figure>
    <figure> 
      <img src="http://geely-cms.cs-labs.online/wp-content/uploads/2024/07/coolray-web_no41116x458O.jpg.png" alt="" />
      <div className="col-12 dark-bg text-center">
        <h2 className="neo">COOLRAY</h2>
      </div>
    </figure>
   <figure> 
      <img src="http://geely-cms.cs-labs.online/wp-content/uploads/2024/07/Emgrand-1116x458-1.jpg.png" alt="" />
      <div className="col-12 dark-bg text-center">
        <h2 className="neo" >EMGRAND</h2>
      </div>
    </figure>
    <figure> 
      <img src="http://geely-cms.cs-labs.online/wp-content/uploads/2024/07/2-1690850960.jpg" alt="" />
      <div className="col-12 dark-bg text-center">
        <h2 className="neo">GX3 PRO</h2>
      </div>
    </figure>

  
  </div>
  </div>
</div>

<div className="News clearPadRight">

  <div className="newsHeading clearSpaceCarouselBottom text-center"> 
    <h2> Spotlight </h2>
  </div> 

  <div className="row"> 
  <div className="col-12 col-md-8 intec bottomMargin"> 
   <div className="newsPad">
    <h2 className="bottomMargin " data-aos="fade-up"> Intec </h2>
 
  <div className="row"> 
    <div className="col-9 col-md-8 d-none d-sm-block" data-aos="fade-up"> 
   <h6>  Design is concerned with the concept of “Intelligent, humane driving” </h6>
    </div>
    <div className="col-10 col-md-4 btn-desk "> 
    <div className="">
         <a className="roundButton" href="/about" target="_blank"> Find Out More </a>
         </div>
    </div>
  </div>
  </div>
  
  </div>

  <div className="col-12 col-md-4 "> 
  <div className="col-12 design designPad text-end bottomMargin"> 
  <h2 className="whiteText bottomMargin" data-aos="fade-up"> Design Philosophy </h2>
  <h6 className="whiteText  d-none d-md-block box-marg" data-aos="fade-up">  Geely auto should look to global trends </h6>
  <a className="roundButtonWhite" href="/design" target="_blank"> Find Out More </a>
        
  </div>

  <div className="col-12 news designPad text-end bottomMargin"> 
  <h2 className="whiteText bottomMargin" data-aos="fade-up"> Latest News </h2>
  <h6 className="whiteText d-none d-md-block box-marg" data-aos="fade-up">  What's happening with Geely Nigeria </h6>
  <a className="roundButtonWhite" href="/news/4" target="_blank"> Find Out More </a>
        
  
  </div>
  
  </div>
  
  </div>

</div>
          
   
  <Footer/>

   </div>

  );
}

export default Landing;
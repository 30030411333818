import $ from "jquery";
import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from "../images/geely.png";
import menu from '../images/menu.png';
import maps from '../images/map.png';
import Nav from './nav.js';
import Footer from './footer.js';
import csrLanding from '../images/csr.jpg';
import eco from '../images/eco.jpg';
import love from '../images/love.jpeg';
import edu from '../images/edu.jpg';
import vision from '../images/vision.jpeg';
import gv from '../images/gv.jpg';
import tech from '../images/tech.jpg';
import dev from '../images/dev.jpg';
import { Form } from "react-router-dom";
import power from '../images/power.jpg' 




function about() {

  window.scrollTo(0, 0)
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid paddingOff">
       
           <div className="col-12" id=""> 
           <div className="row" id="serviceRequestHeight1"> 
           <div className="col-12 col-md-6 mx-auto clearSpaceCarouselVideo clearSpaceCarouselBottomVideo text-center clearTopMob" id="video"> 
           <iframe class="elementor-video-iframe" allowfullscreen="" allow="clipboard-write" title="vimeo Video Player" src="https://player.vimeo.com/video/681192027?autoplay=1&amp;playsinline=1&amp;color&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=0&amp;portrait=0&amp;byline=0#t="></iframe>
         </div>

         <div className="col-12 col-md-6 clearSpace" id="serviceRequestText2"> 

              <h1 className="clearBottomDesktop mobTextCenter">Our Vision</h1>
              <h6 className="clearBottomDesktop mobTextCenter visionPadding"> “Geely Auto is still committed to its brand mission of creating an exceptional mobility experience, which has led us to become China’s leading privately-owned automotive brand. However, our vision for the future is not just to be 
              China’s leading brand, but to become the most competitive and respected Chinese auto brand in the world.” 
              </h6>
      
     
        </div>
           </div>
        </div>

    
                <div className="col-md-12 mx-auto" id="serviceRequestText1"> 

                <div className="row" id=""> 

                <div className="col-12 col-md-5 text-center">

                    <img src={gv} width="408" height="454" className="img-fluid" /> 
                
                </div>
                <div className="col-12 col-md-7" id="serviceRequestText1"> 
                <h2 class="clearSpaceBottom mobTextCenter">Geely Vision</h2>
                <p className="mobTextCenter visionPadding">The global automotive industry is undergoing an important transitional period today as it enters a new era that redefines the vehicles that we take by shifting focus to new energy solutions. Geely Auto is still committed to its brand mission of ‘Making Refined Cars for Everyone’, however, our vision for the future is not just to be China’s leading brand, but to become the most competitive and respected Chinese auto brand in the world. With that being our goal in the evolving global conditions, Geely Auto must embrace the changes that are happening all around us and always keep one foot forward if we are ever to make our vision a reality. We have developed several key new strategies in the past few years that will help us take this leap forward and shall continue to monitor the progress they have made.
                     Our ambition stretches further than just achieving a high volume of sales as the dawn of 
                     new energy vehicles approaches. </p>
                </div>
                
                </div>
        
        </div>


        <div className="col-md-12 mx-auto creamBg" id="serviceRequestText1"> 

<div className="row" id=""> 

<div className="col-12 col-md-7" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter"> Technology </h2>
<p className="mobTextCenter visionPadding">Geely Auto first announced our “Making Refined Cars For Everyone” brand mission in December 2014 with the introduction of the
   flagship Geely Emgrand GT, the brand’s first 3.0 era product followed by the brand’s other bestselling products such as Emgrand X7 Sport, Emgrand GS, 
   and Emgrand GL. The brand’s focus on technological innovation is what makes Geely cars refined. 
   Geely Auto’s 3rd generation models contain Geely’s own iNTEC technology brand products. 
   Geel’s technology brand is a package of leading technologies in the fields of safety, 
   smart connectivity, power, interior environment, and autonomous drive.
To develop such innovative technologies, Geely has established four Global R&D centers which
 have a combined engineering capacity of over 10,000 engineers </p>
</div>

<div className="col-12 col-md-5 text-center">

    <img src={tech} width="408" height="454" className="img-fluid" /> 

</div>


</div>

</div>

   
<div className="col-md-12 mx-auto" id="serviceRequestText1"> 

<div className="row" id=""> 

<div className="col-12 col-md-5 text-center">

    <img src={power} width="408" height="454" className="img-fluid" /> 

</div>
<div className="col-12 col-md-7" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter">Power</h2>
<p className="mobTextCenter visionPadding">Recognizing the importance of new energy for sustainability, Geely Auto made its commitment to new energy in 2015 with our announcement of the “Blue Geely Initiative”. Following the initiative, Geely has committed itself to achieving the new national average fuel economy standers of 5.0L/100 km before the 2020 deadline.

Geely will move towards having new energy vehicles account for over 90% of the brand’s annual sales. We are committed to helping customers achieve the dream of purchasing an eco-friendly vehicle by utilizing our strength to reduce the cost of hybrids and pure electric vehicles to that of a traditional automobile.</p>
</div>

</div>

</div>

<div className="col-md-12 mx-auto creamBg" id="serviceRequestText2"> 

<div className="row" id=""> 

<div className="col-12 col-md-7" id="serviceRequestText1"> 
<h2 class="clearSpaceBottom mobTextCenter"> Development </h2>
<p className="mobTextCenter visionPadding">Geely Auto Group and our parent group, Zhejiang Geely Holding Group are entering into a new stage of development, the global stage. Working together with all companies in the Geely family, we aim to serve the Chinese domestic and export markets with fuel-efficient, reliable, safe, and high-value models. In 2016, Geely Auto Group launched a new global brand, LYNK & CO aiming to serve a new generation of customers.

In 2017 our parent group entered a strategic partnership with Malaysia’s DRB-HICOM bringing Malaysian national brand PROTON and iconic British sports brand Lotus into our family. As one of the first steps in reviving PROTON, Geely Auto’s best-selling SUV Boyue will be rebadged and re-engineered for right-hand drive by PORTON for the Malaysian market.
 In our future, we will bring refined cars not only to everyone in China, 
 but to everyone in the world. </p>
</div>

<div className="col-12 col-md-5 text-center">

    <img src={dev} width="408" height="454" className="img-fluid" /> 

</div>


</div>

</div>

<div className="News clearPadRight">

  <div className="newsHeading clearSpaceCarouselBottom text-center"> 
    <h2> Spotlight </h2>
  </div> 

  <div className="row"> 
  <div className="col-12 col-md-8 intec bottomMargin"> 
   <div className="newsPad">
    <h2 className="bottomMargin " > Intec </h2>
 
  <div className="row"> 
    <div className="col-9 d-none d-sm-block" > 
   <h6>  Design is concerned with the concept of “Intelligent, humane driving” </h6>
    </div>
    <div className="col-10 col-md-3 "> 
    <div className="">
         <a className="roundButton" href="https://buy.geelyksa.com/en/easy-car-financing-available/" target="_blank"> Find Out More </a>
         </div>
    </div>
  </div>
  </div>
  
  </div>

  <div className="col-12 col-md-4 "> 
  <div className="col-12 design designPad text-end bottomMargin"> 
  <h2 className="whiteText bottomMargin" > Design Philosophy </h2>
  <h6 className="whiteText  d-none d-md-block" >  Geely auto should look to global trends </h6>
  <a className="roundButtonWhite" href="https://buy.geelyksa.com/en/easy-car-financing-available/" target="_blank"> Find Out More </a>
        
  </div>

  <div className="col-12 news designPad text-end bottomMargin"> 
  <h2 className="whiteText bottomMargin" > Latest News </h2>
  <h6 className="whiteText d-none d-md-block" >  What's happening with Geely Nigeria </h6>
  <a className="roundButtonWhite" href="https://buy.geelyksa.com/en/easy-car-financing-available/" target="_blank"> Find Out More </a>
        
  
  </div>
  
  </div>
  
  </div>

</div>
          
   
 

      </div>

      <Footer/>
  </div>

  );
}

export default about;
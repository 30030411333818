import $ from "jquery";
import {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftRotate, faArrowRightArrowLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { faArrowRightRotate} from '@fortawesome/free-solid-svg-icons'
import logo from "../images/geely.png";
import Emgrand from '../images/Emgrand.jpg';
import Customer from '../images/customer.png';
import text from '../images/text.png';
import Nav from './nav.js';
import azk from '../images/azkara.png';
import two from '../images/2.png';
import three from '../images/3.png';
import four from '../images/4.png';
import five from '../images/5.png';
import six from '../images/6.png';
import Footer from './footer.js';
import okText from '../images/Ok-text-logo.png';
import oz from '../images/oz.png'; 
import star from '../images/star.png';
import mon from '../images/Mon.png';
import emg from '../images/Emg.png';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import {
   InMemoryCache,
   ApolloClient,
   gql,
 } from '@apollo/client';
 
 const client = new ApolloClient({
   cache:  new InMemoryCache(),
   uri: "https://geely-cms.cs-labs.online/graphql"
 });

Fancybox.bind('[data-fancybox="gallery"]', {
  //
});
let uniqueId

function showDetails(){

  

var element = document.getElementsByClassName('feat_carousal_content')[0]
element.style.opacity = ""
}

let highlightsArray = []
let otherSectionsArray = []
let carViewArray = []
let specsArray = []
let compareArray = []
let vehicleArray = []
let sVehicleImage1
let sVehicleImage2
let sVehicleImage3
let sVehicleImage4
let sVehicleImage5
let sVehicleImage6
let sVehicleImage7
let sVehicleImage8
let sVehicleImage1a
let sVehicleImage2a
let sVehicleImage3a
let sVehicleImage4a
let sVehicleImage5a
let sVehicleImage6a
let sVehicleImage7a
let sVehicleImage8a
let sVehicleImage1b
let sVehicleImage2b
let sVehicleImage3b
let sVehicleImage4b
let sVehicleImage5b
let sVehicleImage6b
let sVehicleImage7b
let sVehicleImage8b
let sVehicleImage1c
let sVehicleImage2c
let sVehicleImage3c
let sVehicleImage4c
let sVehicleImage5c
let sVehicleImage6c
let sVehicleImage7c
let sVehicleImage8c

function Explore() {
   let arrayIndex = 0
   let vehicleList =[]
   let vehicleList1 =[]
   let vehicleList2 =[]
   let vehicleList3 =[]
   let trimId

   const [show, setShow] = useState(false);

   const params = useParams()

   const [brandName, setBrandName] = useState("")
   const [nameplate, setNamePlate] =  useState("")
  const [nameplateCaption, setNamePlateCaption] =  useState("")
  const [highlights, setHightlights] =  useState([])
  const [brochureLink, setBrochureLink] =  useState("")
  const [otherSections, setOtherSections] = useState([]) 
  const [specifications, setSpecifications] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [carHero, setCarHero] = useState("")
  const [carHeroMobile, setCarHeroMobile] = useState("")
  const [engine, setEngine] = useState("")
  const [transmission, setTransmission] = useState("")
  const [power, setPower] = useState("")
  const [capacity, setCapacity] = useState("")
  const [comparison, setComparison] = useState("")
 
  const [gellyBean1, setGellyBean1] = useState("");
  const [gellyBean2, setGellyBean2] = useState("");
  const [gellyBean3, setGellyBean3] = useState("");
  const [gellyBean4, setGellyBean4] = useState("");
  const [brandNameB, setBrandNameB] = useState("");

  const [vehicleImage1, setVehicleImage1] = useState(sVehicleImage1);
  const [vehicleImage2, setVehicleImage2] = useState("");
  const [vehicleImage3, setVehicleImage3] = useState("");
  const [vehicleImage4, setVehicleImage4] = useState("");
  const [vehicleImage5, setVehicleImage5] = useState("");
  const [vehicleImage6, setVehicleImage6] = useState("");
  const [vehicleImage7, setVehicleImage7] = useState("");
  const [vehicleImage8, setVehicleImage8] = useState("");
  const [vehicleImage1a, setVehicleImage1a] = useState(azk);
  const [vehicleImage2a, setVehicleImage2a] = useState(two);
  const [vehicleImage3a, setVehicleImage3a] = useState(three);
  const [vehicleImage4a, setVehicleImage4a] = useState(four);
  const [vehicleImage5a, setVehicleImage5a] = useState(five);
  const [vehicleImage6a, setVehicleImage6a] = useState(six);
  const [vehicleImage7a, setVehicleImage7a] = useState("");
  const [vehicleImage8a, setVehicleImage8a] = useState("");
  const [vehicleImage1b, setVehicleImage1b] = useState(azk);
  const [vehicleImage2b, setVehicleImage2b] = useState(two);
  const [vehicleImage3b, setVehicleImage3b] = useState(three);
  const [vehicleImage4b, setVehicleImage4b] = useState(four);
  const [vehicleImage5b, setVehicleImage5b] = useState(five);
  const [vehicleImage6b, setVehicleImage6b] = useState(six);
  const [vehicleImage7b, setVehicleImage7b] = useState("");
  const [vehicleImage8b, setVehicleImage8b] = useState("");
  const [vehicleImage1c, setVehicleImage1c] = useState(azk);
  const [vehicleImage2c, setVehicleImage2c] = useState(two);
  const [vehicleImage3c, setVehicleImage3c] = useState(three);
  const [vehicleImage4c, setVehicleImage4c] = useState(four);
  const [vehicleImage5c, setVehicleImage5c] = useState(five);
  const [vehicleImage6c, setVehicleImage6c] = useState(six);
  const [vehicleImage7c, setVehicleImage7c] = useState("");
  const [vehicleImage8c, setVehicleImage8c] = useState("");
  const [vehicleImages, setVehicleImages] = useState([]);
  const [vehicleImages1, setVehicleImages1] = useState([]);
  const [vehicleImages2, setVehicleImages2] = useState([]);
  const [vehicleImages3, setVehicleImages3] = useState([]);
  const [vehicleImagesa, setVehicleImagesa] = useState([vehicleImage1a, vehicleImage2a , vehicleImage3a, vehicleImage4a, vehicleImage5a, vehicleImage6a, vehicleImage7a, vehicleImage8a]);
  const [vehicleImagesb, setVehicleImagesb] = useState([vehicleImage1b, vehicleImage2b , vehicleImage3b, vehicleImage4b, vehicleImage5b, vehicleImage6b, vehicleImage7b, vehicleImage8b]);
  const [vehicleView, setVehicleView] = useState("")
  const [vehicleView1, setVehicleView1] = useState("")
  const [vehicleView2, setVehicleView2] = useState("")
  const [vehicleView3, setVehicleView3] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = (e) => {

      setShow(true)
     
      console.log(e.target.id)
      trimId = e.target.id

      client
      .query({
     query: gql`
   query($id: ID!){
      vehicleBy(id:$id) {
         id
         geely {
           brandName 
           specifications {
            fieldGroupName
            specParaText
            specParaTextB
            specParaTextC
            specname
            specimag {
              image {
                mediaItemUrl
              }
              trim
            }
            specimageb {
              trim
              image {
                mediaItemUrl
              }
            }
            specimagec {
              image {
                mediaItemUrl
              }
              trim
            }
          }
          tripleTrim
          singleTrim
          doubleTrim
         }

       }
  }
  `,
  variables: {id:e.target.value}
     })
   .then(async result => {
      console.log(result)
      let specsDetailPack = " "
      let specsHeadingDetailPack = " "
       compareArray = []
      let completeArray = []
      let x
      setBrandNameB(result.data.vehicleBy.geely.brandName)
     
          console.log(result.data.vehicleBy.geely.specifications)
         if(result.data.vehicleBy.geely.specifications){
            if (compareArray.length != result.data.vehicleBy.geely.specifications.length){
               for(x=0; x < result.data.vehicleBy.geely.specifications.length ; x++){ 

                  if(result.data.vehicleBy.geely.singleTrim){
                     
                 //   console.log(result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl)

                   if(trimId === "1"){
                     if (x === 0){
                       
                        specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImage:specifications[0][x].specImage, specTrim:specifications[0][x].specTrim, singleTrim:result.data.vehicleBy.geely.singleTrim}]
                       
                        compareArray.push(specsHeadingDetailPack)


                     }else{

                        specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specText:specifications[x][0].specText}]
                          console.log(specsDetailPack)    
                     }

                   }

                   if(trimId === "2"){
                     if (x === 0){
                        alert('ran')
                        alert(specifications[x][x].specImageb)
                        specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specImage:specifications[x][x].specImageb, specTrim:specifications[x][x].specTrimb, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, singleTrim:result.data.vehicleBy.geely.singleTrim  }]
                        compareArray.push(specsHeadingDetailPack)
                     }else{
                        specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specText:specifications[x][0].specTextB}]
                     }

                   }

 
                   if(trimId === "3"){
                     if (x === 0){
                        specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specImage:specifications[x][x].specImagec, specTrim:specifications[x][x].specTrimc, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, singleTrim:result.data.vehicleBy.geely.singleTrim  }]
                         compareArray.push(specsHeadingDetailPack)
                     }else{
                        specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specText:specifications[x][0].specTextC}]
                     }

                   }
             
                    
                  }

                   
                  if(result.data.vehicleBy.geely.doubleTrim ){
                     if(trimId === "1"){ 
                        if (x === 0){
                           console.log("double trim")
                           specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specImage:specifications[x][x].specImage, specTrim:specifications[x][x].specTrim, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImagec:result.data.vehicleBy.geely.specifications[x].specimageb[0].image.mediaItemUrl, specTrimc:result.data.vehicleBy.geely.specifications[x].specimageb[0].trim, doubleTrim:result.data.vehicleBy.geely.doubleTrim}]
                           compareArray.push(specsHeadingDetailPack)
                        }else{
                           specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specText:specifications[x][0].specText, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specTextC:result.data.vehicleBy.geely.specifications[x].specParaTextB}]
                 
                        }
                     }

                     if(trimId === "2") {

                        if (x === 0){
                           console.log(specifications[x][x].specImageb)
                           specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specImage:specifications[x][x].specImageb, specTrim:specifications[x][x].specTrimb, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImagec:result.data.vehicleBy.geely.specifications[x].specimageb[0].image.mediaItemUrl, specTrimc:result.data.vehicleBy.geely.specifications[x].specimageb[0].trim, doubleTrim:result.data.vehicleBy.geely.doubleTrim}]
                           compareArray.push(specsHeadingDetailPack)
                        }else{
                           specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specText:specifications[x][0].specTextB, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specTextC:result.data.vehicleBy.geely.specifications[x].specParaTextB}]
                 
                        }

                     }

                     if(trimId === "3"){ 

                        if (x === 0){
                           console.log(x)
                           specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specImage:specifications[x][x].specImagec, specTrim:specifications[x][x].specTrimc, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImagec:result.data.vehicleBy.geely.specifications[x].specimageb[0].image.mediaItemUrl, specTrimc:result.data.vehicleBy.geely.specifications[x].specimageb[0].trim, doubleTrim:result.data.vehicleBy.geely.doubleTrim}]
                           compareArray.push(specsHeadingDetailPack)
                        }else{
                           specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specText:specifications[x][0].specTextC, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specTextC:result.data.vehicleBy.geely.specifications[x].specParaTextB}]
                 
                        }



                     }
                
                  }


                  if(result.data.vehicleBy.geely.tripleTrim ){

                     if (trimId === "1"){

                        if (x === 0) {
                           specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specImage:specifications[x][x].specImage, specTrim:specifications[x][x].specTrim, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImagec:result.data.vehicleBy.geely.specifications[x].specimageb[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimageb[0].trim,specImaged:result.data.vehicleBy.geely.specifications[x].specimagec[0].image.mediaItemUrl, specTrimc:result.data.vehicleBy.geely.specifications[x].specimagec[0].trim, tripleTrim:result.data.vehicleBy.geely.tripleTrim}]
                           compareArray.push(specsHeadingDetailPack)
                        }else{
                           specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specText:specifications[x][0].specText, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specTextC:result.data.vehicleBy.geely.specifications[x].specParaTextB,specTextD:result.data.vehicleBy.geely.specifications[x].specParaTextC}]
                 
                        }
                     }

                    if (trimId === "2"){
                   
                     if (x === 0) {
                        specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specImage:specifications[x][x].specImageb, specTrim:specifications[x][x].specTrimb, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImagec:result.data.vehicleBy.geely.specifications[x].specimageb[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimageb[0].trim,specImaged:result.data.vehicleBy.geely.specifications[x].specimagec[0].image.mediaItemUrl, specTrimc:result.data.vehicleBy.geely.specifications[x].specimagec[0].trim, tripleTrim:result.data.vehicleBy.geely.tripleTrim}]
                        compareArray.push(specsHeadingDetailPack)
                     }else{
                        specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specText:specifications[x][0].specTextB, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specTextC:result.data.vehicleBy.geely.specifications[x].specParaTextB,specTextD:result.data.vehicleBy.geely.specifications[x].specParaTextC}]
              
                     }

                      }

                  if(trimId === "3"){ 

                     if (x === 0) {
                        specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specImage:specifications[x][x].specImagec, specTrim:specifications[x][x].specTrimc, specImageb:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImagec:result.data.vehicleBy.geely.specifications[x].specimageb[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimageb[0].trim,specImaged:result.data.vehicleBy.geely.specifications[x].specimagec[0].image.mediaItemUrl, specTrimc:result.data.vehicleBy.geely.specifications[x].specimagec[0].trim, tripleTrim:result.data.vehicleBy.geely.tripleTrim}]
                        compareArray.push(specsHeadingDetailPack)
                     }else{
                        specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname,specText:specifications[x][0].specTextC, specTextB:result.data.vehicleBy.geely.specifications[x].specParaText, specTextC:result.data.vehicleBy.geely.specifications[x].specParaTextB,specTextD:result.data.vehicleBy.geely.specifications[x].specParaTextC}]
              
                     }

                  }

               
                 
                
                     
                 }
              

                 console.log(x)
                    
                 if(x > 0){
         
                  compareArray.push(specsDetailPack)
                  console.log(specsDetailPack)
                 }
                }

               }
      
               }
       
       
   
         setComparison(compareArray)
         console.log(compareArray) 
      


   


   });




   }


        

     useEffect( () => {
  client
.query({
  query: gql`
   query($id: ID!){
      vehicleBy(id:$id) {
         id
         geely {
           brandName
           carBrochure
           carDetails1
           carDetails2
           carDetails3
           engine
           fieldGroupName
           fuelCapacity
           maxpower
           nameplate{
            mediaItemUrl
           }
           nameplateCaption
           transmission
           carHero {
             mediaItemUrl
           }
           carHeroMobile{
            mediaItemUrl
           }
           carFeatures {
             carFeature
             carFeatureImgage {
               mediaItemUrl
             }
           }
           carHighlights {
             highlightHeading
             highlightSubheading
             highlightImage {
               mediaItemUrl
             }
           }
           carImageLeft {
             mediaItemUrl
           }
           carImageRight {
             mediaItemUrl
           }
           carView {
             gellybean {
               mediaItemUrl
             }
             imageroll {
               images {
                 mediaItemUrl
               }
             }
           }
           otherSection {
             sectionDescription
             sectionGallery {
               galleryHeading
               galleryImage {
                 mediaItemUrl
               }
               gallerySubheading
             }
             sectionHead
             sectionHeading
             sectionHero {
               mediaItemUrl
             }
             subImage1 {
               mediaItemUrl
             }
             subImage2 {
               mediaItemUrl
             }
             subImage3 {
               mediaItemUrl
             }
           }
           sliderimage {
             mediaItemUrl
           }

           specifications {
            fieldGroupName
            specParaText
            specParaTextB
            specParaTextC
            specname
            specimag {
              image {
                mediaItemUrl
              }
              trim
            }
            specimageb {
              trim
              image {
                mediaItemUrl
              }
            }
            specimagec {
              image {
                mediaItemUrl
              }
              trim
            }
          }
          tripleTrim
          singleTrim
          doubleTrim
         }

       }
  }
  `,

  variables: {id:params.id}
     })
   .then(async result => {
      console.log(result)
      setBrandName(result.data.vehicleBy.geely.brandName)
      setBrochureLink(result.data.vehicleBy.geely.carBrochure)
      setNamePlate (result.data.vehicleBy.geely.nameplate.mediaItemUrl)
      setNamePlateCaption(result.data.vehicleBy.geely.nameplateCaption)
      setCarHero(result.data.vehicleBy.geely.carHero.mediaItemUrl)
      setCarHeroMobile(result.data.vehicleBy.geely.carHeroMobile.mediaItemUrl)
      setEngine(result.data.vehicleBy.geely.engine)
      setPower(result.data.vehicleBy.geely.maxpower)
      setTransmission(result.data.vehicleBy.geely.transmission)
      setCapacity(result.data.vehicleBy.geely.fuelCapacity)

      let highlightsDetailPack
      let sectionsDetailPack
      let specsDetailPack
      let specsHeadingDetailPack
      let x

         if(result.data.vehicleBy.geely.carHighlights){
      if (highlightsArray.length != result.data.vehicleBy.geely.carHighlights.length){
         for(x=0; x < result.data.vehicleBy.geely.carHighlights.length - 1 ; x++){ 
              
              highlightsDetailPack = [{heading:result.data.vehicleBy.geely.carHighlights[x].highlightHeading, subHeading:result.data.vehicleBy.geely.carHighlights[x].highlightSubheading, Image:result.data.vehicleBy.geely.carHighlights[x].highlightImage.mediaItemUrl}]
        
              highlightsArray.push(highlightsDetailPack)
          
               
         }

   
           console.log(highlightsArray)
         
          }

         }
             if(result.data.vehicleBy.geely.otherSection){

          
         if (otherSectionsArray.length != result.data.vehicleBy.geely.otherSection.length){
            for(x=0; x < result.data.vehicleBy.geely.otherSection.length; x++){ 
                 
                 sectionsDetailPack = [{heading:result.data.vehicleBy.geely.otherSection[x].sectionHeading, description:result.data.vehicleBy.geely.otherSection[x].sectionDescription, hero:result.data.vehicleBy.geely.otherSection[x].sectionHero.mediaItemUrl, subHero1:result.data.vehicleBy.geely.otherSection[x].subImage1.mediaItemUrl, subHero2:result.data.vehicleBy.geely.otherSection[x].subImage2.mediaItemUrl, subHero3:result.data.vehicleBy.geely.otherSection[x].subImage3.mediaItemUrl, galleryheading1:result.data.vehicleBy.geely.otherSection[x].sectionGallery[0].galleryHeading, gallerySubheading1:result.data.vehicleBy.geely.otherSection[x].sectionGallery[0].gallerySubheading, galleryImage1:result.data.vehicleBy.geely.otherSection[x].sectionGallery[0].galleryImage.mediaItemUrl,galleryheading2:result.data.vehicleBy.geely.otherSection[x].sectionGallery[1].galleryHeading, gallerySubheading2:result.data.vehicleBy.geely.otherSection[x].sectionGallery[1].gallerySubheading, galleryImage2:result.data.vehicleBy.geely.otherSection[x].sectionGallery[1].galleryImage.mediaItemUrl, galleryheading3:result.data.vehicleBy.geely.otherSection[x].sectionGallery[2].galleryHeading, gallerySubheading3:result.data.vehicleBy.geely.otherSection[x].sectionGallery[2].gallerySubheading, galleryImage3:result.data.vehicleBy.geely.otherSection[x].sectionGallery[2].galleryImage.mediaItemUrl,galleryheading4:result.data.vehicleBy.geely.otherSection[x].sectionGallery[3].galleryHeading, gallerySubheading4:result.data.vehicleBy.geely.otherSection[x].sectionGallery[3].gallerySubheading, galleryImage4:result.data.vehicleBy.geely.otherSection[x].sectionGallery[3].galleryImage.mediaItemUrl }]
           
                 otherSectionsArray.push(sectionsDetailPack)
             
                  
            }
   
              console.log(otherSectionsArray)
            
   
            }
         }
          console.log(result.data.vehicleBy.geely.specifications)
         if(result.data.vehicleBy.geely.specifications){
            if (specsArray.length != result.data.vehicleBy.geely.specifications.length){
               for(x=0; x < result.data.vehicleBy.geely.specifications.length ; x++){ 
   console.log(result.data.vehicleBy.geely.singletrim)
                  if(result.data.vehicleBy.geely.singleTrim){
                 //   console.log(result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl)
                     if (x === 0){
                        specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specImage:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrim:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specText:result.data.vehicleBy.geely.specifications[x].specParaText, singleTrim:result.data.vehicleBy.geely.singleTrim  }]
                        specsArray.push(specsHeadingDetailPack)
                     }else{
                        specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specText:result.data.vehicleBy.geely.specifications[x].specParaText, singleTrim:result.data.vehicleBy.geely.singleTrim  }]
                     }
                    
                  }

                    
                  if(result.data.vehicleBy.geely.doubleTrim ){
                     if (x === 0){
                        specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specImage:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrim:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImageb:result.data.vehicleBy.geely.specifications[x].specimageb[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimageb[0].trim, specText:result.data.vehicleBy.geely.specifications[x].specParaText, specTextB:result.data.vehicleBy.geely.specifications[x].specParaTextB, doubleTrim:result.data.vehicleBy.geely.doubleTrim}]
                        specsArray.push(specsHeadingDetailPack)
                     }else{
                        specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specText:result.data.vehicleBy.geely.specifications[x].specParaText, specTextB:result.data.vehicleBy.geely.specifications[x].specParaTextB}]
              
                     }
                    
                  }


                  if(result.data.vehicleBy.geely.tripleTrim ){
                     if (x === 0) {
                        specsHeadingDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specImage:result.data.vehicleBy.geely.specifications[x].specimag[0].image.mediaItemUrl, specTrim:result.data.vehicleBy.geely.specifications[x].specimag[0].trim, specImageb:result.data.vehicleBy.geely.specifications[x].specimageb[0].image.mediaItemUrl, specTrimb:result.data.vehicleBy.geely.specifications[x].specimageb[0].trim,specImagec:result.data.vehicleBy.geely.specifications[x].specimagec[0].image.mediaItemUrl, specTrimc:result.data.vehicleBy.geely.specifications[x].specimagec[0].trim, specText:result.data.vehicleBy.geely.specifications[x].specParaText, specTextB:result.data.vehicleBy.geely.specifications[x].specParaTextB,specTextC:result.data.vehicleBy.geely.specifications[x].specParaTextC, tripleTrim:result.data.vehicleBy.geely.tripleTrim}]
                        specsArray.push(specsHeadingDetailPack)
                     }else{
                        specsDetailPack = [{specName:result.data.vehicleBy.geely.specifications[x].specname, specText:result.data.vehicleBy.geely.specifications[x].specParaText, specTextB:result.data.vehicleBy.geely.specifications[x].specParaTextB,specTextC:result.data.vehicleBy.geely.specifications[x].specParaTextC}]
              
                     }
                    
                  }
                    
                    if(x > 0){
                     specsArray.push(specsDetailPack)
                    }
                 
                
                     
                 }
              
                }
      
               }
       
         setHightlights(highlightsArray)
         setSpecifications(specsArray)
         console.log(specsArray) 
         setOtherSections(otherSectionsArray)


          if(result.data.vehicleBy.geely.carView){

     

         if (carViewArray.length != result.data.vehicleBy.geely.carView.length){
            for(x=0; x < result.data.vehicleBy.geely.carView.length; x++){ 
                 
               if(x === 0){
                  setGellyBean1(result.data.vehicleBy.geely.carView[x].gellybean.mediaItemUrl)
                 
                      sVehicleImage1 = result.data.vehicleBy.geely.carView[x].imageroll[0].images.mediaItemUrl
                      sVehicleImage2 = result.data.vehicleBy.geely.carView[x].imageroll[1].images.mediaItemUrl
                      sVehicleImage3 = result.data.vehicleBy.geely.carView[x].imageroll[2].images.mediaItemUrl
                      sVehicleImage4 = result.data.vehicleBy.geely.carView[x].imageroll[3].images.mediaItemUrl
                      sVehicleImage5 = result.data.vehicleBy.geely.carView[x].imageroll[4].images.mediaItemUrl
                      sVehicleImage6 = result.data.vehicleBy.geely.carView[x].imageroll[5].images.mediaItemUrl
                      sVehicleImage7 = result.data.vehicleBy.geely.carView[x].imageroll[6].images.mediaItemUrl
                      sVehicleImage8 = result.data.vehicleBy.geely.carView[x].imageroll[7].images.mediaItemUrl
                    
                 vehicleList.push(sVehicleImage1, sVehicleImage2 , sVehicleImage3, sVehicleImage4, sVehicleImage5, sVehicleImage6, sVehicleImage7, sVehicleImage8)
                 setVehicleImages(vehicleList)
                  setVehicleView(vehicleList[arrayIndex])
                   
               }

               if(x === 1){
                  setGellyBean2(result.data.vehicleBy.geely.carView[x].gellybean.mediaItemUrl)
                  sVehicleImage1a = result.data.vehicleBy.geely.carView[x].imageroll[0].images.mediaItemUrl
                  sVehicleImage2a = result.data.vehicleBy.geely.carView[x].imageroll[1].images.mediaItemUrl
                  sVehicleImage3a = result.data.vehicleBy.geely.carView[x].imageroll[2].images.mediaItemUrl
                  sVehicleImage4a = result.data.vehicleBy.geely.carView[x].imageroll[3].images.mediaItemUrl
                  sVehicleImage5a = result.data.vehicleBy.geely.carView[x].imageroll[4].images.mediaItemUrl
                  sVehicleImage6a = result.data.vehicleBy.geely.carView[x].imageroll[5].images.mediaItemUrl
                  sVehicleImage7a = result.data.vehicleBy.geely.carView[x].imageroll[6].images.mediaItemUrl
                  sVehicleImage8a = result.data.vehicleBy.geely.carView[x].imageroll[7].images.mediaItemUrl
                
                  vehicleList1.push(sVehicleImage1a, sVehicleImage2a , sVehicleImage3a, sVehicleImage4a, sVehicleImage5a, sVehicleImage6a, sVehicleImage7a, sVehicleImage8a)
                  setVehicleImages1(vehicleList1)
                   setVehicleView1(vehicleList1[arrayIndex])
                    
               }

               if(x === 2){ 
                  setGellyBean3(result.data.vehicleBy.geely.carView[x].gellybean.mediaItemUrl)
                  sVehicleImage1b = result.data.vehicleBy.geely.carView[x].imageroll[0].images.mediaItemUrl
                  sVehicleImage2b = result.data.vehicleBy.geely.carView[x].imageroll[1].images.mediaItemUrl
                  sVehicleImage3b = result.data.vehicleBy.geely.carView[x].imageroll[2].images.mediaItemUrl
                  sVehicleImage4b = result.data.vehicleBy.geely.carView[x].imageroll[3].images.mediaItemUrl
                  sVehicleImage5b = result.data.vehicleBy.geely.carView[x].imageroll[4].images.mediaItemUrl
                  sVehicleImage6b = result.data.vehicleBy.geely.carView[x].imageroll[5].images.mediaItemUrl
                  sVehicleImage7b = result.data.vehicleBy.geely.carView[x].imageroll[6].images.mediaItemUrl
                  sVehicleImage8b = result.data.vehicleBy.geely.carView[x].imageroll[7].images.mediaItemUrl
               
                  vehicleList2.push(sVehicleImage1b, sVehicleImage2b , sVehicleImage3b, sVehicleImage4b, sVehicleImage5b, sVehicleImage6b, sVehicleImage7b, sVehicleImage8b)
                  setVehicleImages2(vehicleList2)
                   setVehicleView2(vehicleList2[arrayIndex])
               }

               if(x === 3){ 
              
                setGellyBean4(result.data.vehicleBy.geely.carView[x].gellybean.mediaItemUrl)
                sVehicleImage1c = result.data.vehicleBy.geely.carView[x].imageroll[0].images.mediaItemUrl
                sVehicleImage2c = result.data.vehicleBy.geely.carView[x].imageroll[1].images.mediaItemUrl
                sVehicleImage3c = result.data.vehicleBy.geely.carView[x].imageroll[2].images.mediaItemUrl
                sVehicleImage4c = result.data.vehicleBy.geely.carView[x].imageroll[3].images.mediaItemUrl
                sVehicleImage5c = result.data.vehicleBy.geely.carView[x].imageroll[4].images.mediaItemUrl
                sVehicleImage6c = result.data.vehicleBy.geely.carView[x].imageroll[5].images.mediaItemUrl
                sVehicleImage7c = result.data.vehicleBy.geely.carView[x].imageroll[6].images.mediaItemUrl
                sVehicleImage8c = result.data.vehicleBy.geely.carView[x].imageroll[7].images.mediaItemUrl
             
                vehicleList3.push(sVehicleImage1c, sVehicleImage2c , sVehicleImage3c, sVehicleImage4c, sVehicleImage5c, sVehicleImage6c, sVehicleImage7c, sVehicleImage8c)
                setVehicleImages3(vehicleList3)
                 setVehicleView3(vehicleList3[arrayIndex])
             }


               }
         
   
            }

         }
   });




  


   client
   .query({
     query: gql`
      query{
       vehicles {
         edges {
           node {
             id
             geely {
               brandName
               gelly{
                 mediaItemUrl
               }
               carBrochure
               brandName
               carDetails1
               carDetails2
               carDetails3
           
               carFeatures {
                 carFeature
                 carFeatureImgage {
                   mediaItemUrl
                 }
               }
               carImageLeft {
                 mediaItemUrl
               }
               nameplateCaption
               nameplate {
                 mediaItemUrl
               }
               carImageRight {
                 mediaItemUrl
               }
             }
           }
         }
       }
     }
     `,
   })
   .then(async result => {
     console.log(result)
     
     let automotiveDetailPack 
     
   
     let x
    
     console.log(result.data.vehicles.edges)
   
     if (vehicleArray.length != result.data.vehicles.edges.length){
     for(x=0; x < result.data.vehicles.edges.length; x++){ 
         if(params.id != result.data.vehicles.edges[x].node.id ){
            if (vehicleArray.length != result.data.vehicles.edges.length - 1){
            automotiveDetailPack = [{ carId:result.data.vehicles.edges[x].node.id, brand:result.data.vehicles.edges[x].node.geely.brandName }]
    
            vehicleArray.push(automotiveDetailPack)
            }
          }
          
           
     }
     console.log(vehicleArray)
     
     setVehicles(vehicleArray)
     }
   
   
   });
   

}, [])   

  //window.scrollTo(0, 0)



  function brochure(){
    alert('brochure unavailable at the moment')
  }



function vehicleRotationRight(e) { 
   e.preventDefault()
    // alert(arrayIndex)
   //alert(vehicleImages[arrayIndex])
       
  let indexString = $('.vehicleReview').attr('id')
   //alert(arrayIndex)
  let indexInt = parseInt(indexString)
   //alert(typeof(indexInt))
   //alert(indexInt)

   

        if(indexInt != 7){ 
         //   alert('ran')
             indexInt++
       
        }else{
          indexInt -= 7
        }
  
     // alert(indexInt)
     // alert(vehicleList[indexInt])

     setVehicleView(vehicleImages[indexInt])
           
     return $('.vehicleReview').attr('id', indexInt)
        alert(arrayIndex)
            
 }



 function vehicleRotationLeft() { 
   //alert(arrayIndex)
       
     let indexString = $('.vehicleReview').attr('id')
     let indexInt = parseInt(indexString)
     // alert(typeof(indexInt))
     // alert(indexInt)

     if(indexInt != 0){ 
     
       indexInt--
 
  }else{
    indexInt += 7
  }
  
      //  alert(indexInt)
        setVehicleView(vehicleImages[indexInt])
        $('.vehicleReview').attr('id', indexInt)
     
 }




function vehicleRotationRight1(e) { 
   e.preventDefault()
  // alert(arrayIndex)
   //alert(vehicleImages[arrayIndex])
       
     let indexString = $('.vehicleReview1').attr('id')
     //alert(arrayIndex)
     let indexInt = parseInt(indexString)
     //alert(typeof(indexInt))
      //alert(indexInt)

   

        if(indexInt != 7){ 
         //   alert('ran')
             indexInt++
       
        }else{
          indexInt -= 7
        }
  
     // alert(indexInt)
     // alert(vehicleList[indexInt])
  
   setVehicleView1(vehicleImages1[indexInt])
       
   return $('.vehicleReview1').attr('id', indexInt)
          alert(arrayIndex)
       

     
 }



 function vehicleRotationLeft1() { 
   //alert(arrayIndex)
       
     let indexString = $('.vehicleReview1').attr('id')
     let indexInt = parseInt(indexString)
     // alert(typeof(indexInt))
     // alert(indexInt)

     if(indexInt != 0){ 
     
       indexInt--
 
  }else{
    indexInt += 7
  }
  
      //  alert(indexInt)
        setVehicleView1(vehicleImages1[indexInt])
        $('.vehicleReview1').attr('id', indexInt)
     
 }






function vehicleRotationRight2(e) { 
   e.preventDefault()
  // alert(arrayIndex)
   //alert(vehicleImages[arrayIndex])
       
     let indexString = $('.vehicleReview2').attr('id')
     //alert(arrayIndex)
     let indexInt = parseInt(indexString)
     //alert(typeof(indexInt))
      //alert(indexInt)

   

        if(indexInt != 7){ 
         //   alert('ran')
             indexInt++
       
        }else{
          indexInt -= 7
        }
  
     // alert(indexInt)
     // alert(vehicleList[indexInt])
 

     
      setVehicleView2(vehicleImages2[indexInt])
 
       
    return    $('.vehicleReview2').attr('id', indexInt)
        alert(arrayIndex)
       

     
 }



 function vehicleRotationLeft2() { 
   //alert(arrayIndex)
       
     let indexString = $('.vehicleReview2').attr('id')
     let indexInt = parseInt(indexString)
     // alert(typeof(indexInt))
     // alert(indexInt)

     if(indexInt != 0){ 
     
       indexInt--
 
  }else{
    indexInt += 7
  }
  
      //  alert(indexInt)
        setVehicleView2(vehicleImages2[indexInt])
        $('.vehicleReview2').attr('id', indexInt)
     
 }



 function vehicleRotationRight3(e) { 
  e.preventDefault()
 // alert(arrayIndex)
  //alert(vehicleImages[arrayIndex])
      
    let indexString = $('.vehicleReview3').attr('id')
    //alert(arrayIndex)
    let indexInt = parseInt(indexString)
    //alert(typeof(indexInt))
     //alert(indexInt)

  

       if(indexInt != 7){ 
        //   alert('ran')
            indexInt++
      
       }else{
         indexInt -= 7
       }
 
    // alert(indexInt)
    // alert(vehicleList[indexInt])


    
     setVehicleView3(vehicleImages3[indexInt])

      
   return    $('.vehicleReview3').attr('id', indexInt)
       alert(arrayIndex)
      

    
}



function vehicleRotationLeft3() { 
  //alert(arrayIndex)
      
    let indexString = $('.vehicleReview3').attr('id')
    let indexInt = parseInt(indexString)
    // alert(typeof(indexInt))
    // alert(indexInt)

    if(indexInt != 0){ 
    
      indexInt--

 }else{
   indexInt += 7
 }
 
     //  alert(indexInt)
       setVehicleView3(vehicleImages3[indexInt])
       $('.vehicleReview3').attr('id', indexInt)
    
}




 let featureHighlightDetails

   if(highlights.length > 0){ 
    
      featureHighlightDetails = highlights.map((featureHighlightDetails, i) => 

        
         <div className="item">  
         
         <div class="feat_highlights_detail">
                                <div class="feat_highlights_block img_block">
                                    <div class="feat_info_img">
                                        <img className="highlightImages" decoding="async" src={featureHighlightDetails[0].Image} alt=""/>
                                    </div>
                                </div>
                                <div class="feat_highlights_block">
                                    <div class="feat_content_block">
                                        <div class="feat_content_title"> {featureHighlightDetails[0].heading} </div>
                                        <span class="feat_content_detail">
                                        	&nbsp;
<p dir="ltr" style={{lineHeight: "1.2", textAlign: "justify", marginTop: "0pt", marginBottom: "0pt"}}><span style={{fontSize: "10.5pt", fontFamily: '等线', color:"#000000", backgroundColor: "transparent", fontWeight: "400", fontStyle: "normal", fontVariant: "normal", textDecoration: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap"}}>
   
{featureHighlightDetails[0].subHeading}
   
   </span>
   
   </p>                                        </span>
                                    </div>
                                </div>
                            </div>
           </div>

     
     
           
        )
     
       
     }


     let specificationDetails

     if(specifications.length > 0){ 
      console.log(specifications)
      if(specifications[0][0].singleTrim){ 
       
         specificationDetails = specifications.map((specs, i) => 
            
          

                           i === 0 ?
                                <tbody class="upper_body">
                                                   <tr>
                                                       <td class="spec_model_info first_col fixed_cell"></td>
                                                                                                           <td class="spec_model_info">
                                                               <div class="spec_model_img">
                                                                   <img width="191" height="82" decoding="async" src={specs[0].specImage} alt="" />
                                                               </div>
                                                               <h2 class="spec_model_name">{brandName} {specs[0].specTrim}</h2>
                                                               <div class="spec_model_cta">
                                                               <h6> Compare trims now </h6>
                                                       <select class="form-select form-fields compareDesign mx-auto" id="1" aria-label="Default select example" onChange={handleShow}>
                                                       <option> Compare </option>
                                                           {vehicles.map(brands => (
                                                                      
                                                                          <option value={brands[0].carId}>{brands[0].brand}</option>
                                                          
                                                                         ))}
       
                                                               </select>
                                                               </div>
                                                           </td>
                                                                     </tr>
                                  
       
                                       
                                                  </tbody>


                                                       :
                                                     <tbody class="lower_body">
                                                       <tr>
                                                          <th class="spec_label fixed_cell">{specs[0].specName}</th>
                                                          <td class="spec_value">{specs[0].specText}</td>       
                                                       </tr>

                                                       </tbody>


    
                                                
          )
      }

      if(specifications[0][0].doubleTrim){ 
         specificationDetails = specifications.map((specs, i) => 
       
                 i === 0 ?
                 <tbody class="upper_body">
                                  <tr>
                                        <td class="spec_model_info first_col fixed_cell"></td>
                                                  <td class="spec_model_info">
                                                               <div class="spec_model_img">
                                                                   <img width="191" height="82" decoding="async" src={specs[0].specImage} alt="" />
                                                               </div>
                                                               <h2 class="spec_model_name"> {brandName} {specs[0].specTrim}</h2>
                                                               <div class="spec_model_cta">
                                                               <h6> Compare trims now </h6>
                                                       <select class="form-select form-fields compareDesign mx-auto" id="1" aria-label="Default select example" onChange={handleShow}>
                                                       <option> Compare </option>
                                                          {vehicles.map(brands => (
                                                                        <option value={brands[0].carId}>{brands[0].brand}</option>
                                                          
                                                                         ))}
       
                                                               </select>
                                                               </div>
                                                           </td>
                                                             <td class="spec_model_info">
                                                               <div class="spec_model_img">
                                                                   <img width="191" height="82" decoding="async" src={specs[0].specImageb} alt=""/>
                                                               </div>
                                                               <h2 class="spec_model_name"> {brandName} {specs[0].specTrimb}</h2>
                                                               <div class="spec_model_cta">
                                                               <h6> Compare trims now </h6>
                                                               <select class="form-select form-fields compareDesign mx-auto" id="2" aria-label="Default select example" onChange={handleShow}>
                                                              <option> Compare </option>  
                                                              {vehicles.map(brands => (
                                                                        <option value={brands[0].carId}>{brands[0].brand}</option>
                                                          
                                                                         ))}
                                                                         </select>
                                                               </div>
                                                           </td>
       
       
                                                                                                       </tr>
                                               </tbody>
                                                    :
                                               <tbody class="lower_body">
                                                                                                   <tr>
                                                          <th class="spec_label fixed_cell">{specs[0].specName}</th>
                                                          <td class="spec_value">{specs[0].specText}</td><td class="spec_value">{specs[0].specTextB}</td>        
                                                       </tr>

                                                       </tbody>
    
    
         
          )
      }
      
     
      if(specifications[0][0].tripleTrim){ 
         specificationDetails = specifications.map((specs, i) => 
            i === 0 ?
         <tbody class="upper_body">
                          <tr>
                                <td class="spec_model_info first_col fixed_cell"></td>
                                          <td class="spec_model_info">
                                                       <div class="spec_model_img">
                                                           <img width="191" height="82" decoding="async" src={specs[0].specImage} alt="" />
                                                       </div>
                                                       <h2 class="spec_model_name"> {brandName} {specs[0].specTrim}</h2>
                                                       <div class="spec_model_cta">
                                                         <h6> Compare trims now </h6>
                                                       <select class="form-select form-fields compareDesign mx-auto" id="1" aria-label="Default select example" onChange={handleShow}>
                                                       <option> Compare </option>
                                                       {vehicles.map(brands => (
                                                                <option value={brands[0].carId}>{brands[0].brand}</option>
                                                  
                                                                 ))}

                                                       </select>
                                                       </div>
                                                   </td>
                                                     <td class="spec_model_info">
                                                       <div class="spec_model_img">
                                                           <img width="191" height="82" decoding="async" src={specs[0].specImageb} alt=""/>
                                                       </div>
                                                       <h2 class="spec_model_name">{brandName} {specs[0].specTrimb}</h2>
                                                       <div class="spec_model_cta">
                                                       <h6> Compare trims now </h6>
                                                       <select class="form-select form-fields compareDesign mx-auto" id="2" aria-label="Default select example" onChange={handleShow}>
                                                       <option> Compare </option>
                                                       
                                                       {vehicles.map(brands => (
                                                                <option value={brands[0].carId}>{brands[0].brand}</option>
                                                  
                                                                 ))}
                                                                  </select>
                                                       </div>
                                                   </td>

                                                   <td class="spec_model_info">
                                                       <div class="spec_model_img">
                                                           <img width="191" height="82" decoding="async" src={specs[0].specImagec} alt=""/>
                                                       </div>
                                                       <h2 class="spec_model_name">{brandName} {specs[0].specTrimc}</h2>
                                                       <div class="spec_model_cta">
                                                       <h6> Compare trims now </h6>
                                                       <select class="form-select form-fields compareDesign mx-auto" id="3" aria-label="Default select example" onChange={handleShow}>
                                                       <option> Compare </option>
                                                        {vehicles.map(brands => (
                                                                <option value={brands[0].carId}>{brands[0].brand}</option>
                                                  
                                                                 ))}
                                                                  </select>
                                                       </div>
                                                   </td>


                                                                                               </tr>
                                       </tbody>
                                            :
                                       <tbody class="lower_body">
                                                                                           <tr>
                                                  <th class="spec_label fixed_cell">{specs[0].specName}</th>
                                                  <td class="spec_value">{specs[0].specText}</td><td class="spec_value">{specs[0].specTextB}</td><td class="spec_value">{specs[0].specTextC}</td>    
                                               </tr>

                                               </tbody>
          )
      }


   }



   let compareDetailsPack
   if(comparison.length > 0){ 
      console.log(comparison)
      if(comparison[0][0].singleTrim){ 
         
         compareDetailsPack = comparison.map((specs, i) => 
            
          

                           i === 0 ?
                           <tbody class="upper_body">
                           <tr>
                                 <td class="spec_model_info first_col fixed_cell"></td>
                                           <td class="spec_model_info">
                                                        <div class="spec_model_img">
                                                            <img width="191" height="82" decoding="async" src={specs[0].specImage} alt="" />
                                                        </div>
                                                        <h2 class="spec_model_name"> {brandName} {specs[0].specTrim}</h2>
                                                        <div class="spec_model_cta">
                                                        
                                                    

                                                
                                                        </div>
                                                    </td>
                                                      <td class="spec_model_info">
                                                        <div class="spec_model_img">
                                                            <img width="191" height="82" decoding="async" src={specs[0].specImageb} alt=""/>
                                                        </div>
                                                        <h2 class="spec_model_name"> {brandNameB} {specs[0].specTrimb}</h2>
                                                        <div class="spec_model_cta">
                                                     
                                                        </div>
                                                    </td>


                                                                                                </tr>
                                        </tbody>
                                             :
                                        <tbody class="lower_body">
                                                                                            <tr>
                                                   <th class="spec_label fixed_cell">{specs[0].specName}</th>
                                                   <td class="spec_value">{specs[0].specText}</td><td class="spec_value">{specs[0].specTextB}</td>        
                                                </tr>

                                                </tbody>


    
                                                
          )
      }
      console.log(comparison[0][0].doubleTrim)
      if(comparison[0][0].doubleTrim){ 
         compareDetailsPack = comparison.map((specs, i) => 
       
                 i === 0 ?
                 <tbody class="upper_body">
                 <tr>
                       <td class="spec_model_info first_col fixed_cell"></td>
                                 <td class="spec_model_info">
                                              <div class="spec_model_img">
                                                  <img width="191" height="82" decoding="async" src={specs[0].specImage} alt="" />
                                              </div>
                                              <h2 class="spec_model_name"> {brandName} {specs[0].specTrim}</h2>
                                              <div class="spec_model_cta">
                                           
                                              </div>
                                          </td>
                                            <td class="spec_model_info">
                                              <div class="spec_model_img">
                                                  <img width="191" height="82" decoding="async" src={specs[0].specImageb} alt=""/>
                                              </div>
                                              <h2 class="spec_model_name"> {brandNameB} {specs[0].specTrimb}</h2>
                                              <div class="spec_model_cta">
                                             
                                              </div>
                                          </td>

                                          <td class="spec_model_info">
                                              <div class="spec_model_img">
                                                  <img width="191" height="82" decoding="async" src={specs[0].specImagec} alt=""/>
                                              </div>
                                              <h2 class="spec_model_name">{brandNameB} {specs[0].specTrimc}</h2>
                                              <div class="spec_model_cta">
                                           
                                              </div>
                                          </td>


                                                                                      </tr>
                              </tbody>
                                   :
                              <tbody class="lower_body">
                                                                                  <tr>
                                         <th class="spec_label fixed_cell">{specs[0].specName}</th>
                                         <td class="spec_value">{specs[0].specText}</td><td class="spec_value">{specs[0].specTextB}</td><td class="spec_value">{specs[0].specTextC}</td>    
                                      </tr>

                                      </tbody>
    
         
          )
      }
      
      console.log(comparison[0][0].tripleTrim)
      if(comparison[0][0].tripleTrim){ 
         compareDetailsPack = comparison.map((specs, i) => 
            i === 0 ?
         <tbody class="upper_body">
                          <tr>
                                <td class="spec_model_info first_col fixed_cell"></td>
                                          <td class="spec_model_info">
                                                       <div class="spec_model_img">
                                                           <img width="191" height="82" decoding="async" src={specs[0].specImage} alt="" />
                                                       </div>
                                                       <h2 class="spec_model_name"> {brandName} {specs[0].specTrim}</h2>
                                                       <div class="spec_model_cta">
                                                    
                                                       </div>
                                                   </td>
                                                     <td class="spec_model_info">
                                                       <div class="spec_model_img">
                                                           <img width="191" height="82" decoding="async" src={specs[0].specImageb} alt=""/>
                                                       </div>
                                                       <h2 class="spec_model_name"> {brandNameB} {specs[0].specTrimb}</h2>
                                                       <div class="spec_model_cta">
                                                      
                                                       </div>
                                                   </td>

                                                   <td class="spec_model_info">
                                                       <div class="spec_model_img">
                                                           <img width="191" height="82" decoding="async" src={specs[0].specImagec} alt=""/>
                                                       </div>
                                                       <h2 class="spec_model_name"> {brandNameB} {specs[0].specTrimc}</h2>
                                                       <div class="spec_model_cta">
                                                     
                                                       </div>
                                                   </td>

                                                   <td class="spec_model_info">
                                                       <div class="spec_model_img">
                                                           <img width="191" height="82" decoding="async" src={specs[0].specImaged} alt=""/>
                                                       </div>
                                                       <h2 class="spec_model_name"> {brandNameB} {specs[0].specTrimd} </h2>
                                                       <div class="spec_model_cta">
                                                     
                                                       </div>
                                                   </td>


                                                                                               </tr>
                                       </tbody>
                                            :
                                       <tbody class="lower_body">
                                                                                           <tr>
                                                  <th class="spec_label fixed_cell">{specs[0].specName}</th>
                                                  <td class="spec_value">{specs[0].specText}</td><td class="spec_value">{specs[0].specTextB}</td><td class="spec_value">{specs[0].specTextC}</td>    <td class="spec_value">{specs[0].specTextD}</td>
                                               </tr>

                                               </tbody>
          )
      }


   }



     let otherSectionDetails

     if(otherSections.length > 0){ 
      
        otherSectionDetails = otherSections.map((otherSectionDetails, i) => 
  
          
 
         <div className="detail_page_container"> 
       
         <div class="feature_detail_inner exterior_feat">
                      <h2 class="detail_page_title">{otherSectionDetails[0].heading}</h2>
 
         <div class="feature_main_detail">
                         <div class="image_block">
                            <ul class="left_col">
                               <li>
                                  <img className="othersectionimg" decoding="async" src={otherSectionDetails[0].subHero1} alt=""/>
                               </li>
                               <li>
                                  <img className="othersectionimg"  decoding="async" src={otherSectionDetails[0].subHero2} alt=""/>
                               </li>
                               <li>
                                  <img className="othersectionimg"  decoding="async" src={otherSectionDetails[0].subHero3} alt=""/>
                               </li>
                            </ul>
                            <div class="right_col">
                               <img className="othersectionImg" decoding="async" src={otherSectionDetails[0].hero} alt=""/>
                            </div>
                         </div>
                         <div class="detail_content_col creamBg">
                            <div class="detail_content_title">Reimagine SUV - Spacious Utility Vehicle</div>
                            <div class="highlighted_content">
                               <span class="txt">
                                
                               {otherSectionDetails[0].description}
                                		</span>
                            </div>
                         </div>
                      </div>
 
        
               <div className="feature_detail_carousel owl-carousel owl-theme" id={`featureImage${i}` } >
          
               <div class="item">
                        <div class="features_carousel_blocks">
                              <a class="detail_block" href="" data-fancybox="gallery" data-src={otherSectionDetails[0].galleryImage1} >
                                 <img  decoding="async" src={otherSectionDetails[0].galleryImage1} alt=""/>
                                                                   
                                                 </a>
                        </div>
                                                                     
                        </div>  
 
                    <div class="item">
                        <div class="features_carousel_blocks">
                              <a class="detail_block" href="" data-fancybox="gallery" data-src={otherSectionDetails[0].galleryImage2}>
                                 <img  decoding="async" src={otherSectionDetails[0].galleryImage2} alt=""/>
                                                                   
                                                 </a>
                         </div>
                         </div> 

                         <div class="item">
                        <div class="features_carousel_blocks">
                              <a class="detail_block" href="" data-fancybox="gallery" data-src={otherSectionDetails[0].galleryImage3}>
                                 <img  decoding="async" src={otherSectionDetails[0].galleryImage3} alt=""/>
                                                                   
                                                 </a>
                         </div>
                         </div> 

                         <div class="item">
                        <div class="features_carousel_blocks">
                              <a class="detail_block" href="" data-fancybox="gallery" data-src={otherSectionDetails[0].galleryImage4}>
                                 <img  decoding="async" src={otherSectionDetails[0].galleryImage4} alt=""/>
                                                                   
                                                 </a>
                         </div>
                         </div> 
 
          </div>
         
         </div>
        </div> 
       
       
             
          )
       
         
       }
  
     if(highlights.length > 0 ){ 
      return (
         <div> 
           <Nav />
       
             <div className="container-fluid">
               <div className="row d-none d-md-block" id="exploreHero" style={{backgroundImage:`url(${carHero})`, backgroundSize:"cover", backgroundPosition:"center"}} >
                   
                    <div className="col-6 exploreText"> 
                    <div className="carTitle" id="nameplatePosition"> 
       
                    <img src={nameplate} width="50%"  />
                <h2> 
            
              <span className="car-sub-ttle text-start"> 
                {nameplateCaption}
              </span>
              </h2>
                
          </div>
          <div className="car-detail-btns"  id="exploreButton">
            < div className="row">
       
            <div className="detail-btn explore-btn2 col-12 col-md-4 clearBottom">
              <a href="#" onClick={brochure}>Brochure</a>
              </div>
              <div className="detail-btn finance-btn col-12 col-md-4 clearBottom">
                <a href="/testdrive" target="_blank">Test Drive</a>
                </div>
              
       
            </div>
           
                  </div>
                    </div>
                
               </div>

               <div className="row d-block d-md-none" id="exploreHero" style={{backgroundImage:`url(${carHeroMobile})`, backgroundSize:"cover", backgroundPosition:"center"}} >
                   
                   <div className="col-7" id="nameplatePositionMob"> 
                   <div className="carTitle" > 
      
                   <img src={nameplate} width="100%"  />
               <h2> 
           
             <span className="car-sub-ttle text-start"> 
               {nameplateCaption}
             </span>
             </h2>
               
         </div>
         <div className="car-detail-btns"  id="exploreButton">
           < div className="row">
      
           <div className="detail-btn explore-btn2 col-12 col-md-4 clearBottom">
             <a href="#" onClick={brochure}>Brochure</a>
             </div>
             <div className="detail-btn finance-btn col-12 col-md-4 clearBottom">
               <a href="/testdrive" target="_blank">Test Drive</a>
               </div>
             
      
           </div>
          
                 </div>
                   </div>
               
              </div>
       
               <div className="row">
                   <div className="col-12 marginTopStandard marginBottomStandard text-center"> 
                             <h3> FEATURED HIGHLIGHTS </h3>
       
       
       
                   </div> 
       
                   <div className="col-12"> 
       
       
                <div className="feat_highlights_carousel owl-carousel owl-theme" id="features" data-aos="fade-up">
                
                {featureHighlightDetails}
       
                </div>
        
       
                   </div>
                  
                      
               </div>
       
       
       
              <div className="marginTopStandard"> 
              
              <div className="row background_360">
             <div className="wrapper_360"> 
             <div className=""> 
             
             <div className="car-detail-btns1"  id="exploreButton">
                   <div className="row">
                      <div className="detail-btn explore-btn2b col-6 col-md-3 offset-md-9 clearBottom text-end">
                      <a href="https://www.geelyksa.com/allnew_okavango/">Exterior</a>
                      </div>
                    </div>
             </div>
                  
                  </div>
       
                  
                  <div className="section_title"> 
       
       <h2 className="detail_page_title"> {brandName} 360 
       <img width="13" height="14" decoding="async" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/degree.png" alt=""/>
       </h2>
          
       </div>
       
       <div className="car-view section "> 
       <ul class="nav nav-pills mb-3 row mx-auto gellyWidth" id="pills-tab" role="tablist">
         <li class="nav-item col-3" role="presentation">
           <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
           <img loading="lazy" decoding="async" className="img-fluid gellySize" src={gellyBean1} alt=""/>
             </button>
         </li>
         <li class="nav-item col-3" role="presentation">
           <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
           <img loading="lazy" decoding="async" className="img-fluid gellySize" src={gellyBean2} alt=""/>
             </button>
         </li>

         <li class="nav-item col-3" role="presentation">
           <button class="nav-link" id="pills-tab" data-bs-toggle="pill" data-bs-target="#pills" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
           <img loading="lazy" decoding="async" className="img-fluid gellySize" src={gellyBean3} alt=""/>
             </button>
         </li>

         <li class="nav-item col-3" role="presentation">
           <button class="nav-link" id="p" data-bs-toggle="pill" data-bs-target="#pi" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
           <img loading="lazy" decoding="async" className="img-fluid gellySize" src={gellyBean4} alt=""/>
             </button>
         </li>
       </ul>
       <div class="tab-content" id="pills-tabContent">
         <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          
          
         <div className="carSection"> 
               <div className="row mobWidth">
                  <div className="col-12 col-md-12 text-center CarSpace"> 
            
                  <img width="43" height="43" decoding="async" onClick={vehicleRotationLeft} className="LeftTurn" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/rotate_prev.png" alt="" />
       
              
                      <img src={vehicleView} id="0" className="vehicleReview" width="70%"/>

          

            <img width="43" height="43" decoding="async" onClick={vehicleRotationRight} className="RightTurn" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/rotate_next.png" alt="" />


                  </div>
                  </div>
               </div>
       
          
          </div>
         <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
         <div className="carSection"> 
               <div className="row mobWidth">
                  <div className="col-12 col-md-12 text-center CarSpace"> 
            
                  <img width="43" height="43" decoding="async" onClick={vehicleRotationLeft1} className="LeftTurn" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/rotate_prev.png" alt="" />
       
              
                      <img src={vehicleView1} id="0" className="vehicleReview1" width="70%"/>

          

            <img width="43" height="43" decoding="async" onClick={vehicleRotationRight1} className="RightTurn" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/rotate_next.png" alt="" />


                  </div>
                  </div>
               </div>
            
            </div>
       
            <div class="tab-pane fade" id="pills" role="tabpanel" aria-labelledby="pills-profile-tab">
         <div className="carSection"> 
               <div className="row mobWidth">
                  <div className="col-12 col-md-12 text-center CarSpace"> 
            
                  <img width="43" height="43" decoding="async" onClick={vehicleRotationLeft2} className="LeftTurn" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/rotate_prev.png" alt="" />
       
              
                      <img src={vehicleView2} id="0" className="vehicleReview2" width="70%"/>

          

            <img width="43" height="43" decoding="async" onClick={vehicleRotationRight2} className="RightTurn" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/rotate_next.png" alt="" />


                  </div>
                  </div>
               </div>
            
            </div>

             
            <div class="tab-pane fade" id="pi" role="tabpanel" aria-labelledby="pills-profile-tab">
         <div className="carSection"> 
               <div className="row mobWidth">
                  <div className="col-12 col-md-12 text-center CarSpace"> 
            
                  <img width="43" height="43" decoding="async" onClick={vehicleRotationLeft3} className="LeftTurn" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/rotate_prev.png" alt="" />
       
              
                      <img src={vehicleView3} id="0" className="vehicleReview3" width="70%"/>

          

            <img width="43" height="43" decoding="async" onClick={vehicleRotationRight3} className="RightTurn" src="https://www.geelyksa.com/wp-content/themes/GeelyKsa/assets/images/360_image/rotate_next.png" alt="" />


                  </div>
                  </div>
               </div>
            
            </div>

       </div>
       </div>
       
       <div class="spec_360_container">
                   <div class="spec_360_block">
                       <ul class="spec_360_list">
       
                                                    <li>
                                   <strong>{engine}</strong>
                                   <span>Engine</span>                        
                                   </li>
                                   <li>
                                   <strong>{transmission}</strong>
                                   <span>Transmission</span>                       
                                  </li>
                                   <li>
                                   <strong>{power}</strong>
                                   <span>Max Power</span>                       
                                    </li>
                                                       <li>
                                   <strong>{capacity}</strong>
                                   <span>Fuel tank capacity</span>                        </li>
                                               </ul>
                       <div class="spec_360_cta">
                           <a href="#spec" className="specfinder">Full Specifications                         
                            </a>
                       </div>
                   </div>
               </div>
       
          
             </div>
        
              
               </div>
            
             </div>
       
           
       
   
       
          {otherSectionDetails}

       

       
       
       <div class="detail_page_container" id="spec" >
                               <div class="detail_spec_container">
                                   <h2 class="detail_page_title">SPECIFICATIONS</h2>
                                   <div class="spec_table_wrapper">
                                       <div class="spec_table_block">
                                       <table class="spec_table mBottom">

                                          {specificationDetails}

                                          </table>

                        
                                       </div>
                                   </div>
                               </div>
                           </div>
             </div>


 <Modal show={show} onHide={handleClose} className="compare">
        
        <Modal.Body>
         
        <div class="detail_page_container ">
                               <div class="detail_spec_container">
                                   <h2 class="detail_page_title">Alternative Specifications</h2>
                                   <div class="spec_table_wrapper">
                                       <div class="spec_table_block">
                                       <table class="spec_table mBottom">

                                          {compareDetailsPack}

                                          </table>

                        
                                       </div>
                                   </div>
                               </div>
                           </div>
         
         </Modal.Body>
        <Modal.Footer>
          <Button className="buy-now-btn" variant="primary" onClick={handleClose}>
            Back to {brandName}
          </Button>
        </Modal.Footer>
      </Modal>
       
          <Footer />
       
         </div>
       
         );

     }

}

export default Explore;
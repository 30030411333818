import $ from "jquery";
import logo from "../images/geely.png";
import menu from '../images/menu.png';
import maps from '../images/map.png';
import Nav from './nav.js';
import Footer from './footer.js';
import { useForm } from "react-hook-form";



function Testdrive() {

   
    window.scrollTo(0, 0)

    let { register, handleSubmit, watch, formState: { errors } } = useForm()
    let onSubmit = async function (data){
    
        
    
        
        let submitFinanceSchemeRequest = await fetch('https://charisbrain.herokuapp.com/charis/gwbwebsiteservices/mail/testdrive',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert('Received, we will get back shortly')
      
          })
         
    
    
    };
        
  
  return (
  <div> 
    <Nav />

      <div className="container-fluid">
        <div className="row" id="serviceRequestHeight"> 
           <div className="col-12 text-center" id="serviceRequestText"> 
              <h1 className="clearBottomDesktop clearTopMob"> REQUEST A TEST DRIVE </h1>
              <h6 className="d-none d-sm-block"> Please fill-out accurate information possible so that Geely can provide the most suitable service for you  </h6>
           </div>
        </div>

        <div className="row"> 
         <div className="col-10 col-md-9 mx-auto" id="formArea"> 
          <div className="row inheritHeight" id="formPlate"> 
            <div className="d-none d-md-block col-md-2 creamBg text-center" id="srBar"> 
            <img decoding="async" width="56" height="618" src="https://www.geelyksa.com/wp-content/uploads/2023/10/geely-SERVICE-REQUEST-ENG-1.jpg" class="attachment-full size-full wp-image-221755" alt=""></img>
            </div>
            <div className="col-12 col-md-10 whiteBg formViewPad"> 
                   
            <form onSubmit={handleSubmit(onSubmit)}>

            <div class="mb-3 row">
    <div className="col-12 col-md-6">
    {errors.title?.type === 'required' && <p className="redText" role="alert"> Title is required</p>} 
    <label for="exampleInputPassword1" class="form-label  formFont">Title</label>
    <select class="form-select form-fields" aria-label="Default select example" {...register("title", {required:true})} aria-invalid={errors.title ? "true" : "false"} >
  <option selected value="Mr"> Mr </option>
  <option value="Miss">Miss</option>
  <option value="Mrs">Mrs</option>
 

</select>
</div>
</div>

  <div class="mb-3">
  {errors.fullname?.type === 'required' && <p className="redText" role="alert">Fullname is required</p>} 
    <label for="exampleInputEmail1" class="form-label formFont">Name</label>
    <input type="email" class="form-control form-fields" id="exampleInputEmail1" aria-describedby="emailHelp" {...register("fullname", {required:true})} aria-invalid={errors.fullname ? "true" : "false"} />
  </div>
  <div class="mb-3 row">
    <div className="col-12 col-md-6">
    {errors.email?.type === 'required' && <p className="redText" role="alert">Email is required</p>} 
    <label for="exampleInputPassword1" class="form-label formFont"> Email Address </label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1" {...register("email", {required:true})} aria-invalid={errors.email ? "true" : "false"} />
    </div>
    <div className="col-12 col-md-6">
    {errors.mobile?.type === 'required' && <p className="redText" role="alert">Mobile is required</p>} 
    <label for="exampleInputPassword1" class="form-label  formFont"> Mobile Number </label>
    <input type="password" class="form-control form-fields" id="exampleInputPassword1"  {...register("mobile", {required:true})} aria-invalid={errors.mobile ? "true" : "false"} />
      </div>

  </div>
  <div class="mb-3 row">
    <div className="col-12">
    {errors.model?.type === 'required' && <p className="redText" role="alert"> Model is required</p>} 
    <label for="exampleInputPassword1" class="form-label  formFont">Select the location*</label>
    <select class="form-select form-fields" aria-label="Default select example" {...register("location", {required:true})} aria-invalid={errors.location ? "true" : "false"} >
  <option selected value="1">Emgrand</option>
  <option value="2">Coolray</option>
  <option value="3">GX-3 Pro</option>
  <option value="3">Starray</option>

</select>
    </div>

  </div>


  <div class="mb-3 form-check">
  {errors.terms?.type === 'required' && <p className="redText" role="alert"> Please accept the terms </p>} 
    <input type="checkbox" class="form-check-input" id="exampleCheck1" {...register("terms", {required:true})} aria-invalid={errors.terms ? "true" : "false"}/>
    <label class="form-check-label formFont" for="exampleCheck1">I have read and accept* <span> (Terms and Conditions) </span></label>
  </div>
  <button type="submit" class="blackBtn">Submit</button>
</form>

            
            </div>
          </div>
         </div>
        </div>

      </div>

   <Footer />

  </div>

  );
}
export default Testdrive;